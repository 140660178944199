import * as React from 'react';

function SvgRates(props) {
    return (
        <>
            <svg
                version="1.1"
                height="16"
                width="16"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                //  xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 413.9 413.9"
                style={{ enableBackground: "new 0 0 413.9 413.9" }}
                {...props}
            //    xml:space="preserve"
            >
                <path d="M126.7,15c9,0,18,0,27,0c1,0.2,1.9,0.5,2.9,0.6c10.9,1.6,21.8,2.5,32.5,4.8c19.7,4.2,38.3,11.3,54.2,24.1
	c13.7,11,21.3,24.8,20.8,43.1c-0.6,20.1-0.1,40.2-0.1,60.3c0,1.3,0,2.6,0,4.2c7.9,0,15.3-0.5,22.6,0.1
	c51.9,4.2,96.7,42.8,108.7,93.5c1.6,6.8,2.5,13.7,3.7,20.6c0,6,0,12,0,18c-0.2,1.1-0.6,2.2-0.7,3.2c-4.7,50.3-41.2,93.8-89.9,106.9
	c-7.9,2.1-16.1,3.1-24.2,4.6c-6,0-12,0-18,0c-1-0.2-1.9-0.6-2.9-0.6c-11.4-0.9-22.4-3.7-33-7.8c-20.3-8-37.3-20.5-51.2-37.3
	c-0.8-1-2.6-2-3.9-1.9c-7.2,0.5-14.4,1.7-21.6,2.1c-30.8,1.9-60.9-1.5-89.6-13.2C41.2,330.9,21,318.4,15,291.7c0-71.5,0-143,0-214.5
	c4.3-20.5,18.3-32.9,35.6-42.3c17.9-9.7,37.2-14.9,57.3-17.7C114.1,16.3,120.4,15.7,126.7,15z M174,275.2
	c0,55.7,45.4,101.1,101.1,101.2c55.8,0.1,101.4-45.4,101.4-101.3c0-55.7-45.4-101.1-101.1-101.2C219.5,173.9,173.9,219.4,174,275.2z
	 M142.5,37.4c-21.6,0.1-41,2.4-59.9,8.4C69,50.1,55.9,55.7,45.6,66.1c-10.8,10.8-11.1,22.3,0.1,32.6c5.9,5.4,12.8,10,20,13.6
	c16.3,8.3,34,12,52.2,13.9c27.8,2.9,55.1,1,81.8-7.8c12.7-4.2,24.6-9.8,34.1-19.6c10.4-10.7,10.3-21.9,0-32.7
	c-6-6.3-13.4-10.8-21.2-14.5C189.7,41.1,165.4,37.8,142.5,37.4z M241.5,122.3c-31.2,21.2-65.8,27.6-102,27.5
	c-36.2-0.1-70.9-6.8-102-27.9c0,8.7,0.4,17-0.1,25.1c-0.6,9.7,3.2,17.3,10.6,22.8c7.1,5.2,14.7,10,22.6,13.6
	c15.3,7.1,31.8,10.2,48.5,11.9c20.9,2.1,41.7,1.2,62.2-3.1c1.3-0.3,2.7-0.8,3.6-1.7c15.2-16,33.3-27.3,54.4-33.9
	c0.9-0.3,2.1-1.8,2.1-2.7C241.5,143.6,241.5,133.2,241.5,122.3z M165.7,217.6c-22.7,2.4-44.7,1.7-66.6-2.3
	c-22-4-42.8-11.1-61.6-24.4c0,8.6,0.3,16.5-0.1,24.4c-0.5,8.9,3,16,9.6,21.4c5.6,4.5,11.6,8.9,18,11.9c27,12.9,55.8,16.3,85.4,15.2
	c0.5,0,0.9-0.2,1.7-0.4C153.6,247.4,158.1,232.3,165.7,217.6z M151.9,286.4c-40.7,0.7-79.5-4.4-114.5-27.9c0,8.3,0.3,16.1-0.1,23.9
	c-0.5,9.2,3,16.6,9.9,21.9c6.8,5.2,14.1,10.1,21.8,13.5c26.7,11.8,55,14.7,83.9,13.2c3.6-0.2,7.3-0.6,11.3-1
	C157.4,315.9,153.5,301.7,151.9,286.4z"/>
                <path d="M286.5,353.7c-7.6,0-14.8,0-22.5,0c0-7.1-0.1-14,0.1-21c0.1-2.3-0.7-3.4-2.8-4.4c-11.2-5.3-17.7-14.3-19.5-26.6
	c-0.2-1.2,0-2.4,0-3.8c7.3,0,14.5,0,22,0c0.2,0.8,0.5,1.7,0.7,2.6c1.8,5.7,7.1,9.2,12.6,8.3c5.7-1,9.8-6.1,9.5-11.7
	c-0.3-5.8-4.9-10.4-11.1-10.6c-10.9-0.4-19.9-4.4-26.7-13c-13.5-17.1-7.5-42,12.3-51.3c2.3-1.1,3.1-2.2,3-4.7
	c-0.2-6.9-0.1-13.7-0.1-20.8c7.5,0,14.8,0,22.5,0c0,6.9,0.1,13.7-0.1,20.5c-0.1,2.5,0.6,3.8,3.1,5c11.2,5.4,17.6,14.4,19.3,26.7
	c0.1,1.1,0,2.2,0,3.5c-7.4,0-14.6,0-22,0c-0.3-0.9-0.5-1.8-0.8-2.7c-1.8-5.7-7.2-9.1-12.7-8.1c-5.7,1.1-9.7,6.3-9.3,11.9
	c0.4,5.7,5,10.2,10.9,10.4c6.2,0.2,12.2,1.5,17.5,4.8c11.4,7.1,17.1,17.4,16.5,30.7c-0.6,13.2-7.4,22.9-19.2,28.7
	c-2.6,1.3-3.3,2.7-3.2,5.3C286.6,340,286.5,346.6,286.5,353.7z"/>
            </svg>

        </>
    );
}

export default SvgRates;
