import React, { useEffect, useState } from 'react';
import * as api from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import Button from '../../../components/bootstrap/Button';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { setAlert, setLoading } from '../../../globalState/action-creators';
import 'antd/dist/antd.css';
import Select from '../../../components/bootstrap/forms/Select';
import { allowedVehiclesUnits, USER_ROLE, durationType } from '../../../constants';

const EditUnit = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector((state) => state?.user);

	const token = sessionStorage.getItem('token');
	useEffect(() => {
		if (!token) {
			navigate('/auth-pages/login', { replace: true });
			return;
		}
		gtUnitById(id);
		// eslint-disable-next-line
	}, []);

	const [unitNumberError, setUnitNumberError] = useState(false);
	const [residentAllowedVehiclesError, setResidentAllowedVehiclesError] = useState(false);
	const [guestAllowedVehicleError, setGuestAllowedVehicleError] = useState(false);
	const [guestAccessDurationError, setGuestAccessDurationError] = useState(false);
	const [partnerData, setPartnerData] = useState([]);

	const [formData, setFormData] = useState({
		unitNumber: '',
		residentAllowedVehicles: '',
		guestAllowedVehicles: '',
		guestAccessDuration: '',
		guestAccessDurationType: 'Minutes',
		zone: '',
		partnerEmail: '',
		partnerName: '',
	});

	const onChange = (e) => {
		const { name, value } = e.target;
		if (name === 'unitNumber') {
			setUnitNumberError(false);
		}

		if (name === 'residentAllowedVehicles') {
			setResidentAllowedVehiclesError(false);
		}
		if (name === 'guestAllowedVehicles') {
			setGuestAllowedVehicleError(false);
		}
		if (name === 'guestAccessDuration') {
			setGuestAccessDurationError(false);
		}
		const maxValues = {
			Minutes: 59,
			Hours: 24,
		};

		if (name === 'guestAccessDurationType' || name === 'guestAccessDuration') {
			const currentType =
				name === 'guestAccessDurationType' ? value : formData.guestAccessDurationType;
			const currentDuration =
				name === 'guestAccessDuration'
					? parseInt(value)
					: parseInt(formData.guestAccessDuration);

			if (currentDuration > maxValues[currentType]) {
				setFormData({
					...formData,
					guestAccessDuration: maxValues[currentType].toString(),
					guestAccessDurationType: currentType,
				});
			} else {
				setFormData({
					...formData,
					[name]: name === 'guestAccessDuration' ? value : value,
				});
			}
		} else {
			setFormData({
				...formData,
				[name]: value,
			});
		}
	};

	// GET Unit DATA
	const gtUnitById = async (id) => {
		dispatch(setLoading(true));
		try {
			const { data } = await api.getUnitById(id);
			if (data?.success) {
				setFormData((prevData) => ({
					...prevData,
					...data?.data,
				}));

				const partnerNames = [
					{
						value: data?.data?.partnerName,
						text: data?.data?.partnerName,
					},
				];
				setPartnerData(partnerNames);
			} else {
				dispatch(setAlert(data?.message, 'Error'));
			}
		} catch (error) {
			dispatch(setAlert(error?.message, 'Error'));
		}
		dispatch(setLoading(false));
	};

	const updateUnit = async () => {
		dispatch(setLoading(true));
		try {
			let error;
			const reDigit = /^[0-9]*$/;
			if (_.get(formData, 'unitNumber', '')?.length <= 0) {
				error = true;
				setUnitNumberError(true);
			}
			if (_.get(formData, 'residentAllowedVehicles', '')?.length <= 0) {
				error = true;
				setResidentAllowedVehiclesError(true);
			}
			if (_.get(formData, 'guestAllowedVehicles', '')?.length <= 0) {
				error = true;
				setGuestAllowedVehicleError(true);
			}
			if (_.get(formData, 'guestAccessDuration', '')?.length <= 0) {
				error = true;
				setGuestAccessDurationError(true);
			}
			if (!error) {
				const { data } = await api.updateUnit(formData);
				if (data?.success) {
					navigate('/Unit');
					dispatch(setAlert(data?.message, 'Success'));
				} else {
					dispatch(setAlert(data?.message, 'Error'));
				}
			}
		} catch (error) {
			dispatch(setAlert(error?.message, 'Error'));
		}
		dispatch(setLoading(false));
	};

	return (
		<PageWrapper title='Update Unit'>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xxl-6'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='Edit' iconColor='dark'>
									<CardTitle tag='h4' className='h5'>
										Update Unit
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row'>
									<div className='create_vehicle_inner'>
										<div className='mx-2'>
											<p>
												<strong className='create-vehicle-heading'>
													Unit Features
												</strong>
											</p>
										</div>
										<div className='create-vehicle-wrapper add_vehicle'>
											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Unit Number{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														id='unitName'
														className='form-control addVehicleSelect no-arrows'
														autoComplete='off'
														value={formData?.unitNumber}
														onChange={(e) => {
															onChange(e);
														}}
														name='unitNumber'
														style={styles.inputStyles}
													/>
												</div>
												<span
													id='unitNumber'
													className='text-danger danger-msg'
												>
													{unitNumberError && 'Please Enter Unit Number!'}
												</span>
											</div>

											{user?.role === USER_ROLE.SUPER_ADMIN && (
												<div className='card-info-item card-inner'>
													<div className='label'>
														<p className='mx-3 lable_spacing'>
															Partner Name{' '}
															<span className='text-danger fw-bold'>
																*
															</span>
														</p>
													</div>
													<div className='data'>
														<Select
															ariaLabel='Default select example'
															placeholder=' '
															id='partnerName'
															name='partnerName'
															disabled
															value={formData?.partnerName}
															list={partnerData}
															className='addVehicleSelect inputBoxShadow'
														/>
													</div>
												</div>
											)}

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														# of Resident Vehicles Allowed{' '}
														<span className='text-danger'>*</span>
													</p>
												</div>
												<div className='data'>
													<Select
														ariaLabel='Default select example'
														placeholder=' '
														style={{
															boxShadow:
																'rgb(116 116 116 / 53%) 0px 0px 1px 1px inset',
														}}
														id='residentAllowedVehicles'
														name='residentAllowedVehicles'
														value={formData?.residentAllowedVehicles}
														onChange={(e) => onChange(e)}
														list={allowedVehiclesUnits}
														className='addVehicleSelect inputBoxShadow'
													/>
												</div>
												<span
													id='streetParkingSpace'
													className='text-danger danger-msg'
												>
													{residentAllowedVehiclesError &&
														'Please Enter Resident Allowed Vehicles'}
												</span>
											</div>

											<div className='card-info-item card-inner '>
												<div className='label'>
													<p className='mx-3 lable_spacing'>Zone</p>
												</div>
												<div className='data'>
													<input
														type='text'
														className='form-control addVehicleSelect'
														autoComplete='off'
														name='zone'
														value={formData?.zone}
														onChange={(e) => {
															onChange(e);
														}}
														style={styles.inputStyles}
													/>
												</div>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														# of Guest Vehicles Allowed{' '}
														<span className='text-danger'>*</span>
													</p>
												</div>
												<div className='data'>
													<Select
														ariaLabel='Default select example'
														placeholder=' '
														style={{
															boxShadow:
																'rgb(116 116 116 / 53%) 0px 0px 1px 1px inset',
														}}
														id='guestAllowedVehicles'
														name='guestAllowedVehicles'
														value={formData?.guestAllowedVehicles}
														onChange={(e) => onChange(e)}
														list={allowedVehiclesUnits}
														className='addVehicleSelect inputBoxShadow'
													/>
												</div>
												<span
													id='guestAllowedVehicles'
													className='text-danger danger-msg'
												>
													{guestAllowedVehicleError &&
														'Please Enter Guest Allowed Vehicles'}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Maximum Guest Access Duration
														<span className='text-danger'>*</span>
													</p>
												</div>
												<div className='surcharge_box'>
													<input
														type='text'
														value={formData?.guestAccessDuration}
														className='number_field addVehicleSelect'
														autoComplete='off'
														onChange={(e) => {
															onChange(e);
														}}
														name='guestAccessDuration'
														style={styles?.inputStyles}
													/>
													<hr className='surcharge-seprator'></hr>
													<Select
														ariaLabel='Default select example'
														placeholder=' '
														style={{
															boxShadow:
																'rgb(116 116 116 / 53%) 0px 0px 1px 1px inset',
														}}
														value={formData?.guestAccessDurationType}
														id='guestAccessDurationType'
														name='guestAccessDurationType'
														onChange={(e) => onChange(e)}
														list={durationType}
														className='dropdown_field surcharge_daily'
													/>
												</div>
												<span
													id='guestAccessDuration'
													className='text-danger danger-msg'
												>
													{guestAccessDurationError &&
														'Please Enter Duration.'}
												</span>
											</div>
										</div>
									</div>
								</div>
								<Button className='mx-2 mt-3' color='dark' onClick={updateUnit}>
									Save
								</Button>
								<Button
									className='mt-3'
									color='danger'
									onClick={() => navigate('/Unit')}
								>
									Cancel
								</Button>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

const styles = {
	dateInput: {
		boxShadow: 'rgb(116 116 116 / 53%) 0px 0px 1px 1px inset',
		lineHeight: '1.5',
		padding: '0.5rem 3rem 0.5rem 1rem',
		height: '3.5rem',
		color: '#323232',
	},
	onChangeColor: '#323232',
	inputStyles: {
		boxShadow: 'rgb(116 116 116 / 53%) 0px 0px 1px 1px inset',
		lineHeight: '1.5',
		padding: '0.5rem 3rem 0.5rem 1rem',
		height: '3.5rem',
	},
};

export default EditUnit;
