import * as React from 'react';

function SvgRecentActors(props) {
	return (
		<>
			{/* <svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			className='svg-icon'
			{...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M13 7H3v10h10V7zM8 8c1.07 0 1.95.87 1.95 1.95 0 1.07-.87 1.95-1.95 1.95s-1.95-.87-1.95-1.95S6.93 8 8 8zm3.89 8H4.11v-.65c0-1.3 2.59-1.95 3.89-1.95s3.89.65 3.89 1.95V16z'
				opacity={0.3}
				/>
			<path d='M21 5h2v14h-2zm-4 0h2v14h-2zm-3 14c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1H2c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h12zM3 7h10v10H3V7z' />
			<circle cx={8} cy={9.94} r={1.95} />
			<path d='M8 13.4c-1.3 0-3.89.65-3.89 1.95V16h7.78v-.65c0-1.3-2.59-1.95-3.89-1.95z' />
		</svg> */}

			<svg 
			version="1.1" 
			id="Layer_1"
			height="16"
			width="16"
			 xmlns="http://www.w3.org/2000/svg"
			//   xmlns:xlink="http://www.w3.org/1999/xlink" 
			  x="0px" 
			  y="0px"
			viewBox="0 0 413.9 413.9"
			 style={{enableBackground:"new 0 0 413.9 413.9"}}
			 {...props}
			//   xml:space="preserve"
			  >
				<path d="M196.5,15c7,0,14,0,21,0c5.2,0.7,10.4,1.3,15.5,2.1c87,14.1,148.3,93.2,137.8,180.3c-8.3,68.3-46.6,114.3-110.8,138.7
	c-1.8,0.7-3.7,2.1-4.8,3.7c-11.3,16.4-22.4,32.8-33.5,49.3c-3,4.5-6.5,8.2-11.7,9.9c-2,0-4,0-6,0c-5-1.6-8.4-4.9-11.3-9.2
	c-10.6-15.9-21.5-31.6-32.1-47.5c-2.4-3.6-5.1-6-9.4-7.2c-4.3-1.2-8.4-3.1-12.5-5c-98.2-45.5-127.8-168.4-60.5-253
	c25.4-31.9,58.5-51.6,98.7-59.1C183.4,16.6,189.9,15.9,196.5,15z M206.9,357.1c1-1.5,1.8-2.4,2.5-3.5c8-11.8,16.1-23.5,23.9-35.4
	c3.1-4.7,7.1-7.7,12.6-9.3c65.5-19.6,105.9-85.2,94-152.4c-13-73.5-82.9-122.5-156.3-109.5C113,59.5,63.5,125.2,73.1,195.9
	c7.7,56.9,40.4,94.5,94.8,113c5.4,1.8,9.5,4.5,12.6,9.3C189.1,331.1,197.9,343.8,206.9,357.1z"/>
				<path d="M223.5,202.1c0,13-0.1,26,0,39c0,3.6-1.4,6.2-4.3,8.2c-6.5,4.6-17.1,4.8-23.8,0.6c-3.2-2-4.9-4.7-4.9-8.7
	c0.1-25.9,0.1-51.7,0-77.6c0-4.8,2.2-7.9,6.4-9.6c6.8-2.7,13.7-2.7,20.4,0.2c4.2,1.8,6.3,4.8,6.2,9.6
	C223.4,176.6,223.5,189.3,223.5,202.1z"/>
				<path d="M207.7,102.2c1,0.2,2.9,0.3,4.6,0.8c7.9,2.2,13,9.6,11.6,16.5c-1.6,8-9.9,13.9-18.5,13c-8.6-0.9-15.4-7-15.8-14.3
	C189.2,109.5,196.9,102.3,207.7,102.2z"/>
			</svg>
		</>
	);
}

export default SvgRecentActors;
