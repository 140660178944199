import React, { lazy } from 'react';
import {
	componentsMenu,
	dashboardMenu,
	parkerPages,
} from '../menu';

// Auth
import Login from '../pages/presentation/auth/Login';

// Guest
import AddGuest from '../pages/presentation/buttonsPages/AddGuest';
import DetailGuest from '../pages/presentation/buttonsPages/DetailGuest';
import EditGuest from '../pages/presentation/buttonsPages/EditGuest';

// Units
import AddUnit from '../pages/presentation/buttonsPages/AddUnit';
import EditUnit from '../pages/presentation/buttonsPages/EditUnit';
import DetailUnit from '../pages/presentation/buttonsPages/DetailUnit';

// Residents
import DetailResidents from '../pages/presentation/buttonsPages/residentsButtonPages/DetailResidents';
import AddResident from '../pages/presentation/buttonsPages/residentsButtonPages/AddResident';
import EditResident from '../pages/presentation/buttonsPages/residentsButtonPages/EditResident';

//PaRtner
import DetailPartner from '../pages/presentation/buttonsPages/partnerbuttonpages/DetailPartner';
import EditPartner from '../pages/presentation/buttonsPages/partnerbuttonpages/EditPartner';
import AddPartner from '../pages/presentation/buttonsPages/partnerbuttonpages/AddPartner';

// Rates
import ChangeRatesBtn from '../pages/presentation/buttonsPages/ratesButtonPages/ChangeRatesBtn';
import EditRates from '../pages/presentation/buttonsPages/ratesButtonPages/EditRates';
import EditRatesByPerHour from '../pages/presentation/buttonsPages/ratesButtonPages/EditRatesByPerHour';
import DetailRates from '../pages/presentation/buttonsPages/ratesButtonPages/DetailRates';

// Revenue
import DetailRevenue from '../pages/presentation/buttonsPages/revenueButtonPages/DetailRevenue';

// Reservation
import DetailReservation from '../pages/presentation/buttonsPages/DetailReservation';
import UPDATERESERVATION from '../pages/presentation/buttonsPages/updateReservation/UpdateReservation';

//Hold vehicles
import EditHoldVehicle from '../pages/presentation/buttonsPages/EditVehicle';
import DetailHoldVehicle from '../pages/presentation/buttonsPages/DetailVehicle';
import AddHoldVehicle from '../pages/presentation/buttonsPages/AddVehicle';



const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/single-pages/Dashboard')),
};
const SINGLE = {
	UNITS: lazy(() => import('../pages/presentation/single-pages/Units')),
	RESIDENTS: lazy(() =>
		import('../pages/presentation/single-pages/Residents'),
	),
	PARTNER: lazy(() => import('../pages/presentation/single-pages/Partner')),
	PROFILE: lazy(() => import('../pages/presentation/single-pages/Profile')),
	ALLVEHICLES: lazy(() => import('../pages/presentation/single-pages/Vehicles')),
	GUESTS: lazy(() => import('../pages/presentation/single-pages/Guests')),
	REVENUE: lazy(() => import('../pages/presentation/single-pages/Revenue')),
	RESERVATION: lazy(() => import('../pages/presentation/single-pages/Reservation')),
	RATES: lazy(() => import('../pages/presentation/single-pages/Rates')),
};

const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const CONTENT = {
	CONTENTS: lazy(() => import('../pages/documentation/content/ContentListPage')),
	TYPOGRAPHY: lazy(() => import('../pages/documentation/content/TypographyPage')),
	TABLES: lazy(() => import('../pages/documentation/content/TablesPage')),
	FIGURES: lazy(() => import('../pages/documentation/content/FiguresPage')),
};
const FORMS_PAGE = {
	FORMS: lazy(() => import('../pages/documentation/forms/FormsListPage')),
	FORM_GROUP: lazy(() => import('../pages/documentation/forms/FormGroupPage')),
	FORM_CONTROLS: lazy(() => import('../pages/documentation/forms/FormControlsPage')),
	SELECT: lazy(() => import('../pages/documentation/forms/SelectPage')),
	CHECKS_AND_RADIO: lazy(() => import('../pages/documentation/forms/ChecksAndRadioPage')),
	RANGE: lazy(() => import('../pages/documentation/forms/RangePage')),
	INPUT_GROUP: lazy(() => import('../pages/documentation/forms/InputGroupPage')),
};
const COMPONENTS_PAGE = {
	COMPONENTS: lazy(() => import('../pages/documentation/components/ComponentsListPage')),
	BUTTON: lazy(() => import('../pages/documentation/components/ButtonPage')),
	BUTTON_GROUP: lazy(() => import('../pages/documentation/components/ButtonGroupPage')),
	CARD: lazy(() => import('../pages/documentation/components/CardPage')),
	CAROUSEL: lazy(() => import('../pages/documentation/components/CarouselPage')),
	COLLAPSE: lazy(() => import('../pages/documentation/components/CollapsePage')),
	DROPDOWN: lazy(() => import('../pages/documentation/components/DropdownsPage')),
	LIST_GROUP: lazy(() => import('../pages/documentation/components/ListGroupPage')),
	MODAL: lazy(() => import('../pages/documentation/components/ModalPage')),
	NAVS_TABS: lazy(() => import('../pages/documentation/components/NavsTabsPage')),
	OFF_CANVAS: lazy(() => import('../pages/documentation/components/OffCanvasPage')),
	PAGINATION: lazy(() => import('../pages/documentation/components/PaginationPage')),
	POPOVERS: lazy(() => import('../pages/documentation/components/PopoversPage')),
};

const EXTRA = {
	HOOKS: lazy(() => import('../pages/documentation/extras/HooksPage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	/**
	 * Units
	 */
	{
		path: dashboardMenu.units.path,
		element: <SINGLE.UNITS />,
		exact: true,
	},

	/**
	 * Residents
	 */
	{
		path: dashboardMenu.residents.path,
		element: <SINGLE.RESIDENTS />,
		exact: true,
	},
	/**
	 * Vehicles
	 */
	{
		path: `${dashboardMenu.vehicles.path}`,
		element: <SINGLE.ALLVEHICLES />,
		exact: true,
	},

	/**
	 * Partners
	 */
	{
		path: dashboardMenu.partners.path,
		element: <SINGLE.PARTNER />,
		exact: true,
	},
	/**
	 * Guest
	 */
	{
		path: dashboardMenu.guests.path,
		element: <SINGLE.GUESTS />,
		exact: true,
	},
	/**
	 * Revenue
	 */

	{
		path: `${dashboardMenu.revenue.path}`,
		element: <SINGLE.REVENUE />,
		exact: true,
	},
	/**
	 * Reservations
	 */

	{
		path: `${dashboardMenu.reservation.path}`,
		element: <SINGLE.RESERVATION />,
		exact: true,
	},
	/**
	 * Rates
	 */
	{
		path: dashboardMenu.rates.path,
		element: <SINGLE.RATES />,
		exact: true,
	},
	/**
	 * Login
	 */
	{
		path: 'auth-pages/sign-up',
		element: <Login isSignUp />,
		exact: true,
	},

	/**
	 * BUTTON PAGES
	 */

	/**
	 * Units
	 */

	{
		path: `${dashboardMenu.units.path}/create`,
		element: <AddUnit />,
		exact: true,
	},

	{
		path: `${dashboardMenu.units.path}/edit/:id`,
		element: <EditUnit />,
		exact: true,
	},
	{
		path: `${dashboardMenu.units.path}/detail/:id`,
		element: <DetailUnit />,
		exact: true,
	},

	/**
	 * Guests
	 */

	{
		path: `${dashboardMenu.guests.path}/create`,
		element: <AddGuest />,
		exact: true,
	},

	{
		path: `${dashboardMenu.guests.path}/edit/:id`,
		element: <EditGuest />,
		exact: true,
	},

	{
		path: `${dashboardMenu.guests.path}/detail/:id`,
		element: <DetailGuest />,
		exact: true,
	},

	/**
	 * Residents
	 */

	{
		path: `${dashboardMenu.residents.path}/create`,
		element: <AddResident />,
		exact: true,
	},

	{
		path: `${dashboardMenu.residents.path}/detail/:id`,
		element: <DetailResidents />,
		exact: true,
	},

	{
		path: `${dashboardMenu.residents.path}/edit/:id`,
		element: <EditResident />,
		exact: true,
	},

	/**
	 * Partners
	 */

	{
		path: `${dashboardMenu.partners.path}/create`,
		element: <AddPartner />,
		exact: true,
	},

	{
		path: `${dashboardMenu.partners.path}/edit/:id`,
		element: <EditPartner />,
		exact: true,
	},

	{
		path: `${dashboardMenu.partners.path}/detail/:id`,
		element: <DetailPartner />,
		exact: true,
	},

	/**
	 * Reservations
	 */
	{
		path: `${dashboardMenu.reservation.path}/update/:id`,
		element: <UPDATERESERVATION />,
		exact: true,
	},

	{
		path: `${dashboardMenu.reservation.path}/detail/:id`,
		element: <DetailReservation />,
		exact: true,
	},

	/**
	 * Rates
	 */

	{
		path: `${dashboardMenu.rates.path}/change_price`,
		element: <ChangeRatesBtn />,
		exact: true,
	},

	{
		path: 'change_price/edit/:id/:index',
		element: <EditRates />,
		exact: true,
	},

	{
		path: 'change_price/edit/per_hour/:id/:index',
		element: <EditRatesByPerHour />,
		exact: true,
	},

	{
		path: 'change_price/detail/:id',
		element: <DetailRates />,
		exact: true,
	},

	/**
	 * Revenue
	 */

	{
		path: `${dashboardMenu.revenue.path}/detail/:id`,
		element: <DetailRevenue />,
		exact: true,
	},

	/**
	 * Vehicles
	 */

	{
		path: `${dashboardMenu.vehicles.path}/create`,
		element: <AddHoldVehicle />,
		exact: true,
	},

	{
		path: `${dashboardMenu.vehicles.path}/edit/:id`,
		element: <EditHoldVehicle />,
		exact: true,
	},

	{
		path: `${dashboardMenu.vehicles.path}/detail/:id`,
		element: <DetailHoldVehicle />,
		exact: true,
	},

	{
		path: 'page-layouts/header-and-subheader',
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
		exact: true,
	},
	{
		path: 'page-layouts/only-header',
		element: <PAGE_LAYOUTS.HEADER />,
		exact: true,
	},
	{
		path: 'page-layouts/only-subheader',
		element: <PAGE_LAYOUTS.SUBHEADER />,
		exact: true,
	},
	{
		path: 'page-layouts/only-content',
		element: <PAGE_LAYOUTS.CONTENT />,
		exact: true,
	},
	{
		path: 'aside-types/default-aside',
		element: <PAGE_LAYOUTS.ASIDE />,
		exact: true,
	},
	{
		path: 'aside-types/minimize-aside',
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
		exact: true,
	},
];




const parkerDashBoard = [
	/**
	 * Landing
	 */
	// {
	// 	path: parkerPages.dashboard.path,
	// 	element: <LANDING.DASHBOARD />,
	// 	exact: true,
	// },

	/**
	 * Units
	 */
	// {
	// 	path: parkerPages.units.path,
	// 	element: <SINGLE.UNITS />,
	// 	exact: true,
	// },
	// /**
	//  * Residents
	//  */
	// {
	// 	path: parkerPages.residents.path,
	// 	element: <SINGLE.RESIDENTS_INFORMATION />,
	// 	exact: true,
	// },
	/**
	 * Profile
	 */
	{
		path: `${parkerPages.profile.path}`,
		element: <SINGLE.PROFILE />,
		exact: true,
	},
	/**
	 * Vehicles
	 */
	{
		path: `${parkerPages.vehicles.path}`,
		element: <SINGLE.ALLVEHICLES />,
		exact: true,
	},

	/**
	 * Guest
	 */
	// {
	// 	path: parkerPages.guests.path,
	// 	element: <SINGLE.GUESTS />,
	// 	exact: true,
	// },

	/**
	 * Login
	 */
	{
		path: 'auth-pages/sign-up',
		element: <Login isSignUp />,
		exact: true,
	},

	/**
	 * BUTTON PAGES
	 */

	/**
	 * Guests
	 */

	// {
	// 	path: `${parkerPages.guests.path}/create`,
	// 	element: <AddGuest />,
	// 	exact: true,
	// },

	// {
	// 	path: `${parkerPages.guests.path}/edit/:id`,
	// 	element: <EditGuest />,
	// 	exact: true,
	// },

	// {
	// 	path: `${parkerPages.guests.path}/detail/:id`,
	// 	element: <DetailGuest />,
	// 	exact: true,
	// },

	/**
	 * Vehicles
	 */

	{
		path: `${parkerPages.vehicles.path}/create`,
		element: <AddHoldVehicle />,
		exact: true,
	},

	{
		path: `${parkerPages.vehicles.path}/edit/:id`,
		element: <EditHoldVehicle />,
		exact: true,
	},

	{
		path: `${parkerPages.vehicles.path}/detail/:id`,
		element: <DetailHoldVehicle />,
		exact: true,
	},
	/**
	 * Units
	 */

	// {
	// 	path: `${parkerPages.units.path}/create`,
	// 	element: <AddUnit />,
	// 	exact: true,
	// },

	// {
	// 	path: `${parkerPages.units.path}/edit/:id`,
	// 	element: <EditUnit />,
	// 	exact: true,
	// },
	// {
	// 	path: `${parkerPages.units.path}/detail/:id`,
	// 	element: <DetailUnit />,
	// 	exact: true,
	// },
	/**
	 * Residents
	 */

	// {
	// 	path: `${parkerPages.residents.path}/create`,
	// 	element: <AddResident />,
	// 	exact: true,
	// },

	// {
	// 	path: `${parkerPages.residents.path}/detail/:id`,
	// 	element: <DetailResidents />,
	// 	exact: true,
	// },

	// {
	// 	path: `${parkerPages.residents.path}/edit/:id`,
	// 	element: <EditResident />,
	// 	exact: true,
	// },
];
const documentation = [
	/**
	 * Bootstrap
	 */

	/**
	 * Content
	 */
	{
		path: componentsMenu.content.path,
		element: <CONTENT.CONTENTS />,
		exact: true,
	},
	{
		path: componentsMenu.content.subMenu.typography.path,
		element: <CONTENT.TYPOGRAPHY />,
		exact: true,
	},
	{
		path: componentsMenu.content.subMenu.figures.path,
		element: <CONTENT.FIGURES />,
		exact: true,
	},

	/**
	 * Forms
	 */
	{
		path: componentsMenu.forms.path,
		element: <FORMS_PAGE.FORMS />,
		exact: true,
	},
	{
		path: componentsMenu.forms.subMenu.formGroup.path,
		element: <FORMS_PAGE.FORM_GROUP />,
		exact: true,
	},
	{
		path: componentsMenu.forms.subMenu.formControl.path,
		element: <FORMS_PAGE.FORM_CONTROLS />,
		exact: true,
	},
	{
		path: componentsMenu.forms.subMenu.select.path,
		element: <FORMS_PAGE.SELECT />,
		exact: true,
	},
	{
		path: componentsMenu.forms.subMenu.checksAndRadio.path,
		element: <FORMS_PAGE.CHECKS_AND_RADIO />,
		exact: true,
	},
	{
		path: componentsMenu.forms.subMenu.range.path,
		element: <FORMS_PAGE.RANGE />,
		exact: true,
	},
	{
		path: componentsMenu.forms.subMenu.inputGroup.path,
		element: <FORMS_PAGE.INPUT_GROUP />,
		exact: true,
	},

	/**
	 * Components
	 */
	{
		path: componentsMenu.components.path,
		element: <COMPONENTS_PAGE.COMPONENTS />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.carousel.path,
		element: <COMPONENTS_PAGE.CAROUSEL />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.listGroup.path,
		element: <COMPONENTS_PAGE.LIST_GROUP />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.collapse.path,
		element: <COMPONENTS_PAGE.COLLAPSE />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.pagination.path,
		element: <COMPONENTS_PAGE.PAGINATION />,
		exact: true,
	},

	{
		path: componentsMenu.components.subMenu.card.path,
		element: <COMPONENTS_PAGE.CARD />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.button.path,
		element: <COMPONENTS_PAGE.BUTTON />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.buttonGroup.path,
		element: <COMPONENTS_PAGE.BUTTON_GROUP />,
		exact: true,
	},

	{
		path: componentsMenu.components.subMenu.popovers.path,
		element: <COMPONENTS_PAGE.POPOVERS />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.dropdowns.path,
		element: <COMPONENTS_PAGE.DROPDOWN />,
		exact: true,
	},

	{
		path: componentsMenu.components.subMenu.modal.path,
		element: <COMPONENTS_PAGE.MODAL />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.navsTabs.path,
		element: <COMPONENTS_PAGE.NAVS_TABS />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.offcanvas.path,
		element: <COMPONENTS_PAGE.OFF_CANVAS />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.table.path,
		element: <COMPONENTS_PAGE.TABLE />,
		exact: true,
	},

	/**
	 * Utilities
	 */

	/**
	 * Extra
	 */

	/**
	 * Icons
	 */

	/**
	 * Charts
	 */

	{
		path: componentsMenu.hooks.path,
		element: <EXTRA.HOOKS />,
		exact: true,
	},
];


const contents = [...presentation, ...documentation];
// export const partnerContents = [...partnerDashboard];
export const partnerContents = [...presentation];
export const parkerContents = [...parkerDashBoard];

export default contents;
