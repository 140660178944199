import * as React from 'react';

function SvgSearch2(props) {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="16"
            //   xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 413.9 413.9"
            style={{ enableBackground: "new 0 0 413.9 413.9" }}
            //    xml:space="preserve"
            {...props}
        >
            <path d="M399,319.4c-0.9,3-1.6,6-2.8,8.9c-9.3,22.5-38.1,29.1-56,12.4c-14.9-13.9-28.9-28.7-43.4-43.1c-13.7-13.7-27.4-27.3-41-41.1
	c-1.8-1.8-3.1-1.9-5.4-1C191,280.1,125,244.3,113.4,181.3C102.8,123.8,144.7,69.1,203,64.3c53-4.4,99.9,32.9,107.3,85.5
	c2.4,17.3,0.4,34.2-6,50.4c-1.1,2.8-0.9,4.5,1.3,6.7c25.4,25.3,50.7,50.8,76.2,76c7.8,7.7,15,15.6,17.2,26.9
	C399,312.9,399,316.2,399,319.4z M211.6,240.8c42.7,0,77.2-34.5,77.1-77.2c0-42.7-34.6-77.3-77.2-77.3c-42.5,0-77.2,34.8-77.2,77.4
	C134.3,206.3,168.9,240.8,211.6,240.8z M273.9,242.8c0.5,0.5,1.3,1.5,2.2,2.3c23.7,23.8,47.5,47.5,71.2,71.3c2.6,2.6,5.2,5.4,8,7.9
	c7.5,6.6,19.1,2.5,20.9-7.2c0.9-5.2-1.3-9.1-4.9-12.7c-26.2-26.1-52.3-52.3-78.4-78.4c-0.6-0.6-1.2-1.1-1.9-1.7
	c0.3,0.4,0.6,0.9,0.9,1.3C286.1,231.2,280.2,236.9,273.9,242.8z"/>
            <path d="M15,328.4c2.9-6.3,7.8-8.4,14.7-8.4c80.1,0.2,160.2,0.1,240.3,0.1c6.8,0,11,2.6,12.7,7.7c2.3,6.8-2.4,14-9.7,14.7
	c-1.4,0.1-2.7,0.1-4.1,0.1c-79.6,0-159.2-0.1-238.8,0.1c-7,0-12.1-1.9-15.1-8.3C15,332.5,15,330.4,15,328.4z"/>
            <path d="M15,280.4c2.9-6.3,7.8-8.4,14.7-8.4c36,0.2,71.9,0.1,107.9,0.1c1.7,0,3.5,0,5.2,0.4c5.5,1.3,9.2,6.6,8.6,12
	c-0.7,5.7-5.3,10-11.2,10.1c-8.9,0.1-17.7,0-26.6,0c-27.8,0-55.7-0.2-83.5,0.1c-7,0.1-12.1-1.9-15.1-8.3C15,284.4,15,282.4,15,280.4
	z"/>
            <path d="M15,232.4c2.9-6.3,7.8-8.5,14.7-8.4c20.6,0.3,41.2,0.1,61.8,0.1c8.6,0,14.2,7.6,11.2,15.2c-1.8,4.6-5.4,7.2-10.4,7.2
	c-22,0.1-43.9,0-65.9,0c-5.8,0-8.9-3.6-11.4-8.2C15,236.4,15,234.4,15,232.4z"/>
            <path d="M15,184.4c2.9-6.3,7.8-8.5,14.7-8.4c14.3,0.3,28.7,0.1,43.1,0.1c7.6,0,12.7,4.5,12.7,11.2c0,6.7-5.1,11.3-12.7,11.3
	c-14.2,0-28.5-0.2-42.7,0.1c-7,0.2-12.1-1.9-15.1-8.3C15,188.4,15,186.4,15,184.4z"/>
        </svg>

    );
}

export default SvgSearch2;