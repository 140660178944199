import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import * as api from '../../../api';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { setAlert, setLoading } from '../../../globalState/action-creators';
import Button from '../../../components/bootstrap/Button';

const DetailGuests = () => {
	let { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const token = sessionStorage.getItem('token');

	useEffect(() => {
		if (!token) {
			navigate('/auth-pages/login', { replace: true });
			return;
		}
		fetchGuest();
		// eslint-disable-next-line
	}, []);

	const [guestData, setGuestData] = useState({});

	const fetchGuest = async () => {
		dispatch(setLoading(true));
		try {
			const { data } = await api.getGuestById(id);
			if (data?.success) {
				setGuestData(data?.data);
			} else {
				dispatch(setAlert(data.message, 'Error'));
			}
		} catch (error) {
			dispatch(setAlert(error.message, 'Error'));
		}
		dispatch(setLoading(false));
	};

	return (
		<PageWrapper title={'Guest Detail'}>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xxl-6"'>
						<Card>
							<CardHeader>
								<CardLabel icon='RecentActors' iconColor='primary'>
									<CardTitle tag='h4' className='h5'>
										Guest Profile
									</CardTitle>
								</CardLabel>
								<Button onClick={() => navigate('/guests')}>
									<i
										className='bi bi-x '
										style={{
											fontSize: '26px',
											color: 'rgb(59, 43, 75)',
											fontWeight: '700',
										}}
									></i>
								</Button>
							</CardHeader>
							<CardBody className='table-responsive'>
								<div className='unitSubContainerDetailView text-start'>
									<div className='cardDetailView'>
										<p>
											<strong>Guest Name</strong>
										</p>
										<p>
											{guestData?.guestName?.length > 0
												? guestData?.guestName
												: '-'}
										</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Guest Type</strong>
										</p>
										<p>{guestData?.guestType ? guestData?.guestType : '-'}</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>License Plate</strong>
										</p>
										<p>
											{guestData?.licensePlate
												? guestData?.licensePlate
												: '-'}
										</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Unit</strong>
										</p>
										<p>{guestData?.units ? guestData?.units : 'N/A'}</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Make</strong>
										</p>
										<p>{guestData?.make ? guestData?.make : '-'}</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Model</strong>
										</p>
										<p>{guestData?.model ? guestData?.model : '-'}</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Arrival Date</strong>
										</p>
										<p>
											{guestData?.MoveInDate?.length > 0
												? moment(guestData?.MoveInDate).format('MM/DD/YYYY')
												: '-'}
										</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>End Date</strong>
										</p>
										<p>
											{guestData?.MoveOutDate?.length > 0
												? moment(guestData?.MoveOutDate).format(
														'MM/DD/YYYY',
												  )
												: '-'}
										</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Arrival Time</strong>
										</p>
										<p>
											{guestData?.startTime?.length > 0
												? moment(guestData?.startTime, 'HH:mm').format(
														'hh:mm A',
												  )
												: '-'}
										</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>End Time</strong>
										</p>
										<p>
											{guestData?.endTime?.length > 0
												? moment(guestData?.endTime, 'HH:mm').format(
														'hh:mm A',
												  )
												: '-'}
										</p>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default DetailGuests;
