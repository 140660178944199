import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as api from '../../../../api';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import { setAlert, setLoading } from '../../../../globalState/action-creators';
import Button from '../../../../components/bootstrap/Button';

const DetailPartner = () => {
	let { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const token = sessionStorage.getItem('token');

	useEffect(() => {
		if (!token) {
			navigate('/auth-pages/login', { replace: true });
			return;
		}
		fetchPartner();
		// eslint-disable-next-line
	}, []);

	const [partnerData, setPartnerData] = useState({});

	const fetchPartner = async () => {
		dispatch(setLoading(true));
		try {
			const { data } = await api.getPartnerById(id);
			if (data?.success) {
				setPartnerData(data?.data);
			} else {
				dispatch(setAlert(data.message, 'Error'));
			}
		} catch (error) {
			dispatch(setAlert(error.message, 'Error'));
		}
		dispatch(setLoading(false));
	};

	return (
		<PageWrapper title={'Partner Detail'}>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xxl-6"'>
						<Card>
							<CardHeader>
								<CardLabel icon='person' iconColor='primary'>
									<CardTitle tag='h4' className='h5'>
										Partner Profile
									</CardTitle>
								</CardLabel>
								<Button onClick={() => navigate('/partners')}>
									<i
										className='bi bi-x '
										style={{
											fontSize: '26px',
											color: 'rgb(59, 43, 75)',
											fontWeight: '700',
										}}
									></i>
								</Button>
							</CardHeader>
							<CardBody className='table-responsive'>
								<div className='unitSubContainerDetailView text-start'>
									<div className='cardDetailView'>
										<p>
											<strong>Partner Name</strong>
										</p>
										<p>
											{partnerData?.partnerName?.length > 0
												? `${partnerData?.partnerName} `
												: '-'}
										</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Partner Contact Email</strong>
										</p>
										<p>{partnerData?.email ? partnerData?.email : '-'}</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Partner Contact Phone </strong>
										</p>
										<p>
											{partnerData?.phoneNumber
												? partnerData?.phoneNumber
												: '-'}
										</p>
									</div>
									<div className='cardDetailView'>
										<p>
											<strong>Partner Address </strong>
										</p>
										<p>
											{partnerData?.partnerAddress
												? partnerData?.partnerAddress
												: '-'}
										</p>
									</div>
									<div className='cardDetailView'>
										<p>
											<strong>Business Name </strong>
										</p>
										<p>
											{partnerData?.businessName
												? partnerData?.businessName
												: '-'}
										</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Admin Email</strong>
										</p>
										<p>
											{partnerData?.adminEmail
												? partnerData?.adminEmail
												: '-'}
										</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Resident Spaces</strong>
										</p>
										<p>
											{partnerData?.residentSpaces
												? partnerData?.residentSpaces
												: '-'}
										</p>
									</div>

									<div className='cardDetailView'>
										<p>
											<strong>Guest Spaces</strong>
										</p>
										<p>
											{partnerData?.surplusSpaces
												? partnerData?.surplusSpaces
												: '-'}
										</p>
									</div>
									{/* <div className='cardDetailView'>
										<p>
											<strong>Total Spaces</strong>
										</p>
										<p>
											{partnerData?.totalSpaces
												? partnerData?.totalSpaces
												: '-'}
										</p>
									</div> */}

									{/* <div className=' text-start image_wrapper'>
										<p>
											<strong>Logo</strong>
										</p>
										<div className='imagesPreview'>
											{partnerData &&
											partnerData?.partnerLogo &&
											partnerData?.partnerLogo?.length > 0 ? (
												partnerData?.partnerLogo?.map((image, index) => {
													return (
														<React.Fragment key={index}>
															<img
																src={image?.imagePreviewUrl}
																alt='Logo'
																style={{ width: '200px' }}
															/>
														</React.Fragment>
													);
												})
											) : (
												<p>-</p>
											)}
										</div>
									</div> */}
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default DetailPartner;
