import * as React from 'react';

function SvgReservation(props) {
	return (
		<svg version="1.1" id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg" 
        width="16"
        height="16"
        // xmlns:xlink="http://www.w3.org/1999/xlink" 
        x="0px"
         y="0px"
	 viewBox="0 0 413.9 413.9"
      style={{enableBackground:"new 0 0 413.9 413.9"}}
      {...props}
    //   xml:space="preserve"
      >
<path d="M15,363c0-96,0-192,0-288c0.2-0.6,0.6-1.1,0.7-1.7C18.8,57.5,28,46.9,43,41.3c7.6-2.8,15.5-2.3,23.3-2.3
	c6.8,0,13.7,0,20.9,0c0-3.5,0.2-6.6,0-9.7C86.7,22.4,89.3,17.5,96,15c1.8,0,3.5,0,5.2,0c6.5,2.7,9.1,7.6,8.5,14.5
	c-0.2,3.1,0,6.2,0,9.3c16.9,0,33.2,0,49.9,0c0-3.2,0.2-6.2,0-9.2c-0.6-6.9,2-11.8,8.4-14.6c1.7,0,3.5,0,5.2,0
	c6.7,2.4,9.4,7.3,8.9,14.3c-0.2,3.2,0,6.4,0,9.5c16.8,0,33.1,0,49.7,0c0-3.3,0.2-6.5,0-9.5c-0.4-7,2.3-11.8,8.9-14.3
	c1.8,0,3.5,0,5.2,0c6.4,2.8,9,7.7,8.4,14.6c-0.3,3,0,6.1,0,9.3c16.8,0,33.1,0,49.9,0c0-3.2,0.2-6.3,0-9.4c-0.6-6.9,2-11.8,8.5-14.5
	c1.8,0,3.5,0,5.2,0c6.6,2.5,9.2,7.5,8.8,14.4c-0.2,3.1,0,6.2,0,9.7c1.7,0,3.1,0,4.4,0c9,0,18-0.3,27,0.1c17.4,0.7,30,9.1,37.4,24.9
	c1.6,3.4,2.4,7.3,3.5,10.9c0,96,0,192,0,288c-0.2,0.7-0.6,1.3-0.7,2c-3.4,16.1-13,26.8-28.5,32.1c-2.2,0.8-4.5,1.2-6.7,1.8
	c-104,0-208,0-312,0c-0.6-0.2-1.1-0.6-1.7-0.7c-12.9-2.4-22.8-9.3-29-20.8C17.8,373,16.7,367.8,15,363z M37.6,134.1
	c-0.1,1.3-0.2,2.4-0.2,3.5c0,73,0,146,0,219c0,12.1,8,19.9,20.4,19.9c99.4,0,198.7,0,298.1,0c12.7,0,20.5-7.8,20.5-20.6
	c0-72.6,0-145.2,0-217.8c0-1.3,0-2.6,0-3.9C263.2,134.1,150.5,134.1,37.6,134.1z M109.7,61.6c0,4,0.1,7.6,0,11.2
	c0,1.7-0.1,3.5-0.6,5.2c-1.6,5.1-6.6,8.1-12,7.5c-5.4-0.6-9.5-5.1-9.8-11.1c-0.2-4.2,0-8.4,0-12.8c-11.4,0-22.3-0.5-33.1,0.1
	c-8.5,0.5-15.7,7.2-16.3,15.6c-0.7,11.2-0.2,22.4-0.2,33.7c113,0,225.8,0,338.8,0c0-10.3,0.1-20.3,0-30.3
	c-0.1-11.1-8.1-19.1-19.2-19.3c-8.5-0.1-17,0-25.5,0c-1.6,0-3.2,0-5.1,0c0,4.2,0.1,8,0,11.7c-0.1,7.4-4.6,12.3-11.2,12.4
	c-6.6,0-11.1-4.9-11.2-12.3c0-3.8,0-7.7,0-11.6c-16.9,0-33.2,0-49.9,0c0,4.4,0.1,8.6,0,12.8c-0.2,7-6,11.8-12.8,10.9
	c-5.6-0.8-9.5-5.4-9.6-11.6c-0.1-4.1,0-8.1,0-12.1c-16.9,0-33.1,0-49.7,0c0,3.9,0,7.6,0,11.3c-0.1,7.6-4.7,12.6-11.4,12.6
	c-6.6-0.1-11-5-11.1-12.4c0-3.8,0-7.6,0-11.5C142.8,61.6,126.5,61.6,109.7,61.6z"/>
<path d="M139.9,303.3c0,7.5,0,15,0,22.5c-0.1,8.5-4.5,12.9-13,12.9c-15,0-30,0-44.9,0c-8.2,0-12.8-4.6-12.8-12.7
	c0-15.1,0-30.2,0-45.3c0-8.2,4.6-12.7,12.8-12.7c15.1-0.1,30.2-0.1,45.3,0c8.1,0,12.5,4.5,12.6,12.5
	C140,288.1,139.9,295.7,139.9,303.3z M117.2,290.8c-8.8,0-17.1,0-25.3,0c0,8.6,0,16.9,0,25.2c8.6,0,16.9,0,25.3,0
	C117.2,307.4,117.2,299.2,117.2,290.8z"/>
<path d="M274,303.4c0-7.6-0.1-15.2,0-22.8c0.1-8.1,4.5-12.6,12.6-12.6c15.2-0.1,30.5-0.1,45.7,0c8,0,12.5,4.6,12.5,12.6
	c0.1,15.1,0,30.2,0,45.3c0,8.2-4.6,12.8-12.8,12.8c-15.1,0.1-30.2,0.1-45.3,0c-8.2,0-12.6-4.6-12.7-12.9
	C274,318.4,274,310.9,274,303.4z M322.1,290.7c-8.6,0-16.9,0-25.3,0c0,8.6,0,16.9,0,25.3c8.6,0,16.8,0,25.3,0
	C322.1,307.5,322.1,299.3,322.1,290.7z"/>
<path d="M104.4,242.3c-7.7,0-15.5,0-23.2,0c-7.3-0.1-12-4.6-12-11.9c-0.1-15.6-0.1-31.2,0-46.8c0-7.3,4.8-11.8,12.1-11.9
	c15.6,0,31.2-0.1,46.8,0c7,0,11.7,4.4,11.8,11.3c0.2,16,0.2,32,0,47.9c-0.1,6.9-4.9,11.3-11.8,11.3
	C120.1,242.3,112.3,242.3,104.4,242.3z M91.8,219.5c8.6,0,16.9,0,25.4,0c0-8.5,0-16.8,0-25.2c-8.6,0-16.9,0-25.4,0
	C91.8,202.8,91.8,211,91.8,219.5z"/>
<path d="M242.3,303.5c0,7.7,0,15.5,0,23.2c-0.1,7.1-4.5,11.9-11.6,12c-15.9,0.2-31.7,0.2-47.6,0c-6.9-0.1-11.4-4.9-11.4-11.7
	c-0.1-15.7-0.1-31.5,0-47.2c0-6.8,4.3-11.6,11-11.7c16.1-0.2,32.2-0.2,48.3,0c6.9,0.1,11.2,4.9,11.2,11.9
	C242.3,287.8,242.3,295.7,242.3,303.5z M219.5,316.1c0-8.7,0-17,0-25.3c-8.6,0-16.8,0-25.1,0c0,8.6,0,16.9,0,25.3
	C202.9,316.1,211.1,316.1,219.5,316.1z"/>
<path d="M309.6,171.7c7.7,0,15.5,0,23.2,0c7.1,0.1,11.8,4.6,11.9,11.6c0.1,15.7,0.1,31.5,0,47.2c-0.1,7.3-4.8,11.7-12.2,11.8
	c-15.5,0-31,0-46.4,0c-7.4,0-12-4.4-12.1-11.8c-0.1-15.6-0.1-31.2,0-46.8c0.1-7.6,4.7-11.9,12.4-11.9
	C294.1,171.6,301.9,171.6,309.6,171.7z M296.8,194.3c0,8.7,0,17,0,25.2c8.6,0,16.9,0,25.1,0c0-8.6,0-16.8,0-25.2
	C313.4,194.3,305.2,194.3,296.8,194.3z"/>
<path d="M198,208.6c9.1-9.1,17.5-17.4,25.8-25.9c2.8-2.8,6-4.5,10.1-3.9c4.4,0.6,7.6,3,9.2,7.3c1.5,4.1,0.9,8.1-2.1,11.2
	c-11.5,11.8-23.2,23.4-35,35c-4.3,4.2-10.5,4.2-14.9,0.1c-6.2-5.8-12.2-11.8-18-18c-4.1-4.4-3.7-11.1,0.4-15.3
	c4.2-4.3,10.9-4.6,15.5-0.3C192.1,201.7,194.9,205.1,198,208.6z"/>
</svg>

	);
}

export default SvgReservation;
