import * as React from 'react';

function SvgEmojiTransportation(props) {
	return (
		// <svg
		// 	xmlns='http://www.w3.org/2000/svg'
		// 	height='1em'
		// 	viewBox='0 0 24 24'
		// 	width='1em'
		// 	className='svg-icon'
		// 	{...props}>
		// 	<path fill='none' d='M0 0h24v24H0z' />
		// 	<path d='M20.57 10.66c-.14-.4-.52-.66-.97-.66h-7.19c-.46 0-.83.26-.98.66L10 14.77l.01 5.51c0 .38.31.72.69.72h.62c.38 0 .68-.38.68-.76V19h8v1.24c0 .38.31.76.69.76h.61c.38 0 .69-.34.69-.72l.01-1.37v-4.14l-1.43-4.11zm-8.16.34h7.19l1.03 3h-9.25l1.03-3zM12 17c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm8 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z' />
		// 	<path d='M14 9h1V3H7v5H2v13h1V9h5V4h6z' />
		// 	<path d='M5 11h2v2H5zM10 5h2v2h-2zM5 15h2v2H5zM5 19h2v2H5z' />
		// </svg>

		<svg
			version="1.1"
			id="Layer_1"
			height="16"
			width="16"
			xmlns="http://www.w3.org/2000/svg"
			//    xmlns:xlink="http://www.w3.org/1999/xlink" 
			x="0px"
			y="0px"
			viewBox="0 0 413.9 413.9"
			style={{ enableBackground: "new 0 0 413.9 413.9" }}
			{...props}
		//   xml:space="preserve"
		>
			<g>
				<path d="M173.9,310.4c-1.9,0-3.4,0-4.9,0c-35.5,0-71,0-106.5,0c-12.3,0-21.9-7.1-24.7-18.4c-0.7-2.6-0.8-5.4-0.8-8.2
   C37,220.4,37,157,37,93.6c0-16.5,10-26.4,26.4-26.4c11.7,0,23.5,0,35.5,0c0.4-2.4,0.3-4.8,1.1-6.8c2-4.9,4.2-9.8,6.9-14.4
   c2.6-4.4,5.8-8.5,8.8-12.6c4-5.4,11.2-5.4,15.3,0c3,4,5.8,8.1,9.1,12.7c2.9-4.1,5.6-7.9,8.4-11.7c5.1-7.1,11.6-7.2,16.6-0.2
   c2.8,3.8,5.5,7.7,8.5,11.9c3-4.3,5.8-8.2,8.6-12.1c4.8-6.6,11.4-6.6,16.2,0c2.8,3.8,5.5,7.7,8.6,12.1c3-4.3,5.8-8.2,8.6-12.1
   c4.8-6.6,11.4-6.7,16.2,0c2.8,3.8,5.5,7.7,8.6,12.1c1.9-2.6,3.8-4.8,5.3-7.3c7.4-12.8,15.7-12.8,23.2,0.1c1.4,2.4,3.2,4.5,5.1,7.2
   c2.7-3.8,5.3-7.3,7.7-10.8c6-8.5,12.1-8.5,18.2,0.1c1.7,2.3,3.2,4.8,5,7c5.5,7.2,9.9,14.8,10,24.6c1.6,0,2.9,0,4.2,0
   c10.8,0,21.5-0.1,32.3,0c15.3,0.1,25.5,10,25.5,25.4c0.1,64.1,0.1,128.3,0,192.4c0,15.4-10.5,25.4-25.9,25.4
   c-35.4,0-70.8,0-106.1,0c-1.5,0-3,0-4.9,0c0.3,1.5,0.4,2.7,0.7,3.8c5.6,20.8,16.3,38.5,32.4,52.9c2.2,1.9,4.4,3,7.4,2.8
   c3.2-0.2,6.5-0.1,9.7-0.1c6.1,0,9.7,2.7,9.8,7.4c0.1,4.7-3.5,7.5-9.6,7.5c-27.5,0-55,0-82.5,0c-27.6,0-55.3,0-82.9,0c-1,0-2,0-3,0
   c-4.5-0.2-7.8-3.5-7.7-7.5c0.1-4,3.2-7.1,7.6-7.3c4.5-0.2,9,0.1,13.5-0.1c1.4-0.1,3-0.5,4.1-1.3c17.6-14.8,28.9-33.5,34.6-55.7
   C173.9,312.1,173.9,311.5,173.9,310.4z M113.9,244.8c62.3,0,124.1,0,186.1,0c0-1.8,0-3.3,0-4.7c0-56.5-0.1-113,0.1-169.5
   c0-9.1-4.1-15.6-9.6-22.2c-2.3,3.3-4.8,6.1-6.6,9.4c-5.5,9.8-14.6,9.8-20.2-0.2c-1.8-3.2-4.2-6-6.6-9.3c-3.1,4.3-5.8,8.2-8.5,12
   c-4.7,6.4-11.7,6.4-16.3-0.1c-2.8-3.9-5.6-7.8-8.6-12c-2.4,3.4-4.8,6.2-6.6,9.3c-5.6,9.8-14.1,11-20.4-0.1
   c-1.8-3.1-4.1-5.9-6.4-9.1c-2.9,4.1-5.6,7.8-8.3,11.6c-5.3,7.4-11.6,7.4-16.9,0.1c-2.7-3.8-5.4-7.6-8.4-11.7c-3,4.3-5.7,8-8.4,11.8
   c-4.9,6.8-11.9,6.8-16.8-0.1c-2.7-3.8-5.4-7.6-8.1-11.3c-7,7.2-9.8,15.2-9.7,25c0.4,55.5,0.2,111,0.2,166.5
   C113.9,241.6,113.9,243.1,113.9,244.8z M361.9,260.3c-103.5,0-206.7,0-310.2,0c0,7.6,0,14.8,0,22c0,10.2,3,13.2,13.1,13.2
   c94.7,0,189.4,0,284.2,0c1.1,0,2.3,0,3.4,0c5.2-0.2,9.3-3.2,9.5-8.3C362.2,278.3,361.9,269.3,361.9,260.3z M98.9,81.9
   c-12,0-23.6,0-35.2,0c-8.5,0-12,3.5-12,12.1c0,49,0,98,0,146.9c0,1.3,0.1,2.6,0.2,3.9c15.9,0,31.4,0,47,0
   C98.9,190.6,98.9,136.6,98.9,81.9z M315.2,82.2c0,54.3,0,108.5,0,162.6c15.8,0,31.2,0,46.8,0c0.1-1,0.2-1.7,0.2-2.4
   c0-49.9,0-99.8,0-149.7c0-6.9-3.8-10.7-10.7-10.8c-11.2-0.1-22.5,0-33.8,0C316.8,82,316,82.1,315.2,82.2z M254.2,369.6
   c-15.4-17.5-25.6-36.8-29.7-58.9c-11.9,0-23.5,0-35.1,0c-4.2,22.4-14.5,41.7-29.6,58.9C191.4,369.6,222.4,369.6,254.2,369.6z"/>
				<path d="M123.1,385.2c-0.4,0-0.9,0-1.3,0c-4.8-0.3-8.3-3.7-8.2-8c0.1-4.3,3.5-7.6,8.1-7.7c2.2-0.1,4.5-0.1,6.6,0
   c2.3,0,4.6,0,6.9-0.1c1.5-0.1,2.9-0.5,3.8-1.2c17.1-14.3,28.6-33,34.4-55.5c0.1-0.3,0.1-0.7,0.1-1.2c0-0.1,0-0.3,0-0.4l-34.1,0
   c-25.6,0-51.2,0-76.8,0c-12.4,0-22.3-7.4-25.2-18.8c-0.7-2.9-0.8-6-0.8-8.3c0-64.4-0.1-128.3,0-190.2c0-16.8,10.1-26.9,26.9-26.9
   c8.1,0,16.2,0,24.4,0l10.6,0c0.1-0.6,0.1-1.2,0.2-1.8c0.1-1.7,0.3-3.2,0.9-4.6c2-4.7,4.2-9.8,6.9-14.5c2.1-3.6,4.7-7,7.1-10.3
   c0.6-0.8,1.1-1.5,1.7-2.3c2-2.7,4.9-4.3,8-4.3c0,0,0,0,0,0c3.1,0,6,1.6,8.1,4.3c2,2.7,4,5.5,6.1,8.5c0.8,1.2,1.7,2.4,2.6,3.6
   l8-11.1c2.6-3.7,5.7-5.6,8.8-5.6c0,0,0,0,0,0c3.1,0,6.1,1.9,8.6,5.4c1.9,2.6,3.8,5.3,5.7,8l2.4,3.3l2.4-3.4c2-2.8,3.9-5.5,5.8-8.2
   c2.4-3.4,5.4-5.2,8.5-5.2c0,0,0,0,0,0c3.1,0,6.1,1.9,8.5,5.2c1.9,2.6,3.7,5.2,5.7,8l2.5,3.5l2.3-3.3c2-2.8,3.9-5.6,5.9-8.3
   c2.4-3.4,5.4-5.2,8.5-5.2c0,0,0,0,0,0c3.1,0,6.1,1.8,8.5,5.2c1.9,2.6,3.8,5.2,5.7,8c0.8,1.1,1.6,2.3,2.5,3.5c0.5-0.6,1-1.3,1.4-1.9
   c1.3-1.6,2.4-3.2,3.4-4.8c3.8-6.5,7.8-9.8,12-9.8c0,0,0,0,0,0c4.2,0,8.3,3.4,12.1,10c0.9,1.6,2,3,3.2,4.6c0.5,0.6,1,1.3,1.5,2
   l2.2-3c1.8-2.5,3.5-4.8,5.2-7.2c3.1-4.3,6.3-6.5,9.4-6.5c0,0,0,0,0,0c3.2,0,6.4,2.2,9.6,6.7c0.5,0.8,1.1,1.5,1.6,2.3
   c1.1,1.6,2.2,3.2,3.4,4.7c4.8,6.3,9.8,14.1,10.1,24.4h3.7c3.3,0,6.7,0,10,0c7.3,0,14.8,0,22.3,0c15.5,0.1,26,10.5,26,25.9
   c0.1,64.2,0.1,128.9,0,192.4c0,15.5-10.6,25.9-26.4,25.9c-25.2,0-50.4,0-75.6,0l-34.9,0c0,0.3,0.1,0.5,0.1,0.8
   c0.1,0.9,0.3,1.6,0.5,2.4c5.5,20.7,16.4,38.4,32.3,52.6c2.3,2.1,4.4,2.9,7,2.7c2.3-0.2,4.7-0.1,7-0.1c0.9,0,1.9,0,2.8,0
   c6.4,0,10.2,2.9,10.3,7.8c0,2.1-0.7,3.9-2,5.3c-1.8,1.8-4.6,2.7-8.1,2.7c-20.2,0-40.3,0-60.5,0l-104.9,0l-0.9,0
   C123.7,385.2,123.4,385.2,123.1,385.2z M125.8,370.3c-1.4,0-2.7,0-4.1,0.1c-4.1,0.1-7,3-7.1,6.8c-0.1,3.8,3,6.8,7.3,7
   c0.7,0,1.4,0,2.1,0l0.9,0l104.9,0c20.2,0,40.3,0,60.5,0c3.3,0,5.9-0.8,7.4-2.4c1.2-1.2,1.7-2.7,1.7-4.6c0-4.3-3.4-6.8-9.3-6.9
   c-0.9,0-1.9,0-2.8,0c-2.3,0-4.6-0.1-6.9,0.1c-2.9,0.2-5.3-0.7-7.7-2.9c-16-14.3-27-32.2-32.6-53.1c-0.2-0.8-0.3-1.6-0.5-2.5
   c-0.1-0.4-0.1-0.9-0.2-1.4l-0.1-0.6l36,0c25.2,0,50.4,0,75.6,0c15.2,0,25.4-10,25.4-24.9c0.1-63.5,0.1-128.3,0-192.4
   c0-15-9.8-24.8-25-24.9c-7.4,0-15,0-22.2,0c-3.3,0-6.7,0-10,0h-4.7l0-0.5c-0.1-10.3-5.1-18.1-9.9-24.3c-1.2-1.5-2.3-3.2-3.4-4.7
   c-0.5-0.8-1.1-1.5-1.6-2.3c-2.9-4.1-5.9-6.2-8.7-6.3c0,0,0,0,0,0c-2.8,0-5.7,2.1-8.6,6.1c-1.7,2.4-3.4,4.7-5.2,7.2l-3,4.1l-0.4-0.6
   c-0.6-0.9-1.3-1.7-1.9-2.5c-1.2-1.6-2.3-3.1-3.3-4.7c-3.6-6.3-7.4-9.5-11.2-9.5c0,0,0,0,0,0c-3.8,0-7.5,3.1-11.1,9.3
   c-1,1.7-2.2,3.3-3.5,4.9c-0.6,0.8-1.2,1.6-1.8,2.4l-0.4,0.6l-0.4-0.6c-1-1.4-1.9-2.7-2.9-4c-2-2.8-3.8-5.4-5.7-8
   c-2.3-3.1-4.9-4.8-7.7-4.8c0,0,0,0,0,0c-2.8,0-5.4,1.7-7.7,4.8c-1.9,2.7-3.9,5.4-5.9,8.3l-3.1,4.4l-3.3-4.6c-2-2.8-3.8-5.4-5.7-8
   c-2.3-3.1-4.9-4.8-7.7-4.8c0,0,0,0,0,0c-2.8,0-5.4,1.6-7.7,4.8c-1.9,2.7-3.9,5.4-5.8,8.2l-3.2,4.5l-3.2-4.5c-2-2.7-3.8-5.4-5.7-8
   c-2.4-3.3-5.1-5-7.8-5c0,0,0,0,0,0c-2.8,0-5.6,1.8-8,5.2L140,47.1l-0.4-0.6c-1-1.5-2-2.9-3-4.2c-2.1-3-4-5.8-6.1-8.5
   c-1.8-2.5-4.5-3.9-7.3-3.9c0,0,0,0,0,0c-2.8,0-5.4,1.4-7.2,3.9c-0.6,0.8-1.1,1.5-1.7,2.3c-2.5,3.3-5,6.7-7.1,10.3
   c-2.7,4.7-4.9,9.7-6.8,14.4c-0.5,1.3-0.7,2.8-0.8,4.3c-0.1,0.8-0.1,1.5-0.3,2.3l-0.1,0.4l-11.5,0c-8.2,0-16.3,0-24.4,0
   c-16.2,0-25.9,9.7-25.9,25.9c0,61.8,0,125.8,0,190.2c0,2.3,0.1,5.3,0.8,8c2.8,11.1,12.1,18,24.2,18c25.6,0,51.2,0,76.8,0l35.1,0
   l0,0.5c0,0.4,0,0.7,0,0.9c0,0.5,0,1-0.1,1.4c-5.8,22.7-17.5,41.5-34.7,56c-1.2,1-3,1.4-4.4,1.5c-2.3,0.1-4.7,0.1-6.9,0.1
   C127.4,370.3,126.6,370.3,125.8,370.3z M255.3,370.1h-96.6l0.7-0.8c15.8-18.1,25.5-37.3,29.5-58.7l0.1-0.4h35.9l0.1,0.4
   c4,21.5,13.7,40.7,29.6,58.7L255.3,370.1z M160.9,369.1h92.2c-15.5-17.8-25-36.7-29-57.9h-34.3
   C185.7,332.3,176.3,351.3,160.9,369.1z M350.8,296c-0.3,0-0.6,0-0.9,0l-0.9,0H64.8c-10.4,0-13.6-3.2-13.6-13.7l0-22.5h311.2v0.5
   c0,2.7,0,5.4,0.1,8c0.1,6.2,0.1,12.7-0.2,19c-0.2,5-4.2,8.5-10,8.7C351.8,296,351.3,296,350.8,296z M52.2,260.8l0,21.5
   c0,9.9,2.8,12.7,12.6,12.7l285.1,0c0.8,0,1.6,0,2.4,0c5.2-0.2,8.8-3.3,9-7.8c0.3-6.3,0.2-12.7,0.2-18.9c0-2.5,0-5-0.1-7.5H52.2z
	M99.4,245.4h-48l0-0.5c0-0.4,0-0.8-0.1-1.2c0-0.9-0.1-1.8-0.1-2.7c0-49,0-98,0-146.9c0-8.9,3.6-12.6,12.5-12.6
   c8.1,0,16.2,0,24.4,0l11.3,0V245.4z M52.4,244.4h46v-162l-10.3,0c-8.2,0-16.3,0-24.4,0c-8.3,0-11.5,3.3-11.5,11.6
   c0,49,0,98,0,146.9c0,0.9,0.1,1.8,0.1,2.7C52.4,243.9,52.4,244.2,52.4,244.4z M362.4,245.4h-47.7V81.8l0.4-0.1
   c0.2,0,0.5-0.1,0.8-0.1c0.6-0.1,1.2-0.2,1.8-0.2l7.6,0c8.6,0,17.4,0,26.2,0c7.2,0.1,11.2,4.1,11.2,11.3c0,42.2,0,84.4,0,126.6l0,23
   c0,0.5,0,1-0.1,1.6c0,0.3-0.1,0.6-0.1,0.9L362.4,245.4z M315.7,244.4h45.8c0-0.2,0-0.3,0-0.5c0-0.5,0.1-1,0.1-1.5l0-23
   c0-42.2,0-84.4,0-126.6c0-6.7-3.5-10.2-10.2-10.3c-8.7-0.1-17.6-0.1-26.1,0l-7.6,0c-0.5,0-1.1,0.1-1.7,0.2c-0.1,0-0.2,0-0.3,0.1
   V244.4z M300.5,245.3H113.4v-5.2c0-14.1,0-28.2,0-42.3c0-40.7,0.1-82.8-0.2-124.2c-0.1-10.2,3.1-18.3,9.9-25.4l0.4-0.4l2.9,4.1
   c1.8,2.6,3.7,5.2,5.5,7.8c2.3,3.2,5.2,5,8,5h0c2.9,0,5.7-1.7,7.9-4.9c1.8-2.6,3.6-5.1,5.6-7.9l3.2-4.5l3.1,4.4
   c1.9,2.7,3.8,5.3,5.7,7.9c2.5,3.4,5.2,5.2,8,5.2c0,0,0,0,0,0c2.8,0,5.6-1.9,8.1-5.3l8.7-12.1l0.4,0.6c0.7,1,1.5,2,2.2,3
   c1.6,2.1,3,4,4.2,6.2c2.9,5,6.3,7.7,9.8,7.7c0,0,0,0,0,0c3.5,0,7-2.7,9.7-7.5c1.2-2.1,2.7-4.1,4.3-6.2c0.8-1,1.5-2,2.3-3.1l0.4-0.6
   l3.2,4.5c2,2.7,3.9,5.4,5.8,8c2.2,3,5,4.7,7.8,4.7c2.8,0,5.6-1.6,7.7-4.6c1.9-2.6,3.8-5.3,5.7-8l3.2-4.5l0.4,0.6c0.8,1,1.5,2,2.3,3
   c1.6,2.1,3.1,4.1,4.4,6.3c2.6,4.7,6,7.2,9.7,7.2c0,0,0,0,0,0c3.6,0,7-2.5,9.6-7.1c1.3-2.2,2.8-4.3,4.4-6.4c0.7-1,1.5-2,2.2-3
   l0.4-0.5l0.4,0.5c6,7.3,9.7,13.6,9.7,22.5c-0.2,42.4-0.2,85.5-0.2,127.2c0,14.1,0,28.2,0,42.3V245.3z M114.4,244.3h185.1v-4.2
   c0-14.1,0-28.2,0-42.3c0-41.7-0.1-84.8,0.2-127.2c0-8.3-3.4-14.4-9.1-21.3c-0.6,0.9-1.2,1.7-1.9,2.5c-1.6,2.1-3.1,4.1-4.3,6.3
   c-2.7,4.9-6.4,7.6-10.4,7.6c0,0,0,0,0,0c-4,0-7.8-2.8-10.6-7.8c-1.2-2.1-2.7-4.1-4.3-6.2c-0.6-0.8-1.2-1.6-1.9-2.5l-2.4,3.4
   c-2,2.8-3.8,5.4-5.7,8c-2.3,3.2-5.4,5-8.5,5c-3.2,0-6.2-1.8-8.6-5.1c-1.9-2.6-3.8-5.3-5.8-8l-2.4-3.4c-0.6,0.9-1.3,1.7-1.9,2.6
   c-1.6,2.1-3,4-4.2,6.1c-2.9,5.2-6.7,8-10.6,8c0,0,0,0,0,0c-3.9,0-7.6-2.8-10.7-8.2c-1.2-2.1-2.6-4-4.2-6.1
   c-0.6-0.8-1.2-1.6-1.8-2.5l-7.9,11c-2.7,3.8-5.8,5.8-8.9,5.8c0,0,0,0,0,0c-3.1,0-6.1-2-8.8-5.7c-1.9-2.6-3.7-5.2-5.7-7.9l-2.3-3.2
   l-2.4,3.4c-1.9,2.7-3.8,5.3-5.6,7.9c-2.5,3.4-5.6,5.3-8.7,5.3c0,0,0,0,0,0c-3.2,0-6.4-1.9-8.9-5.4c-1.9-2.6-3.7-5.2-5.5-7.8
   l-2.2-3.1c-6.4,6.9-9.3,14.4-9.2,24.2c0.3,41.4,0.2,83.5,0.2,124.2c0,14.1,0,28.2,0,42.3V244.3z"/>
			</g>
			<g>
				<path d="M207,115.3c23.5,0,47,0,70.5,0c5.1,0,8.2,2.8,8.3,7.2c0.1,3.8-2.4,6.8-6.1,7.3c-1.5,0.2-3,0.2-4.5,0.2
   c-45.5,0-91,0-136.5,0c-1.5,0-3,0-4.5-0.2c-3.7-0.6-6.2-3.7-6.1-7.4c0.1-3.7,2.8-6.7,6.5-7c3.1-0.2,6.2-0.1,9.4-0.1
   C165,115.3,186,115.3,207,115.3z"/>
				<path d="M207.8,130.5c-23,0-46.1,0-69.1,0h-0.3c-1.4,0-2.8,0-4.3-0.2c-3.9-0.6-6.6-3.9-6.5-7.9c0.1-4,3.1-7.2,7-7.5
   c2.3-0.2,4.6-0.1,6.9-0.1c0.8,0,1.6,0,2.5,0c0,0,0,0,0,0c18.4,0,36.8,0,55.1,0l7.9,0l19.1,0c17.1,0,34.3,0,51.4,0
   c5.3,0,8.7,2.9,8.8,7.6c0.1,4-2.6,7.2-6.6,7.8c-1.4,0.2-2.8,0.2-4.2,0.2l-0.4,0C252.7,130.5,230.2,130.5,207.8,130.5z M138.7,129.4
   c45.5,0,91,0,136.5,0l0.4,0c1.3,0,2.7,0,4-0.2c3.5-0.5,5.8-3.2,5.7-6.8c-0.1-4.2-3-6.7-7.8-6.7c-17.1,0-34.3,0-51.4,0l-19.1,0
   l-7.9,0c-18.4,0-36.8,0-55.1,0c0,0,0,0,0,0c-0.8,0-1.7,0-2.5,0c-2.2,0-4.6,0-6.8,0.1c-3.4,0.2-6,3-6.1,6.5
   c-0.1,3.5,2.2,6.3,5.6,6.9c1.3,0.2,2.7,0.2,4.1,0.2H138.7z"/>
			</g>
			<g>
				<path d="M207,83c23.5,0,47,0,70.5,0c4.8,0,7.8,2.4,8.2,6.4c0.4,4.2-2.2,7.6-6.4,8.1c-1.4,0.2-2.7,0.1-4.1,0.1c-45.5,0-91,0-136.5,0
   c-1.5,0-3,0-4.5-0.2c-3.7-0.5-6.2-3.6-6.1-7.4c0.1-3.7,2.8-6.7,6.5-7c3.6-0.2,7.2-0.1,10.9-0.1C166,83,186.5,83,207,83z"/>
				<path d="M211.9,98.1c-24.4,0-48.8,0-73.2,0l-0.6,0c-1.3,0-2.7,0-4-0.2c-3.9-0.6-6.6-3.9-6.5-7.9c0.1-4.1,3-7.2,7-7.5
   c2.7-0.2,5.4-0.1,8.1-0.1c0.9,0,1.9,0,2.8,0c15.8,0,31.7,0,47.5,0l14,0l19.5,0c17,0,34,0,51,0c5,0,8.3,2.6,8.7,6.8
   c0.5,4.5-2.4,8.2-6.9,8.7c-1.2,0.1-2.4,0.1-3.5,0.1l-0.7,0C254.1,98.1,233,98.1,211.9,98.1z M138.7,97.1c45.5,0,91,0,136.5,0l0.7,0
   c1.1,0,2.3,0,3.4-0.1c3.9-0.5,6.4-3.6,6-7.6c-0.4-3.8-3.2-5.9-7.8-5.9c-17,0-34,0-51,0l-19.5,0l-14,0c-15.8,0-31.7,0-47.5,0
   c-1,0-1.9,0-2.9,0c-2.6,0-5.3,0-8,0.1c-3.4,0.2-5.9,2.9-6,6.5c-0.1,3.5,2.2,6.4,5.6,6.9c1.3,0.2,2.6,0.2,3.8,0.2L138.7,97.1z"/>
			</g>
			<g>
				<path d="M128.1,205.3c0-3.5-0.1-7,0-10.5c0.1-6.2,4.2-10.3,10.4-10.4c7.2-0.1,14.5-0.1,21.7,0c6.1,0.1,10.3,3.9,10.5,9.9
   c0.3,7.5,0.3,15,0,22.5c-0.2,6-4.3,10-10.4,10.1c-7.2,0.2-14.5,0.2-21.7,0c-6.5-0.2-10.4-4.3-10.5-10.8
   C128,212.5,128.1,208.9,128.1,205.3z M143,199.4c0,4.4,0,8.4,0,12.6c4.3,0,8.5,0,12.7,0c0-4.3,0-8.4,0-12.6
   C151.4,199.4,147.4,199.4,143,199.4z"/>
				<path d="M149.4,227.6c-3.6,0-7.2,0-10.9-0.1c-6.6-0.2-10.8-4.5-11-11.2c0-2.5,0-5.1,0-7.5c0-1.1,0-2.2,0-3.3c0-1.1,0-2.2,0-3.3
   c0-2.4,0-4.8,0-7.2c0.2-6.5,4.4-10.8,10.9-10.9c6.9-0.1,14-0.1,21.7,0c6.5,0.1,10.8,4.2,11,10.4c0.3,7.2,0.3,14.8,0,22.5
   c-0.2,6.3-4.5,10.4-10.9,10.6C156.7,227.5,153.1,227.6,149.4,227.6z M149.1,184.8c-3.6,0-7.2,0-10.6,0.1c-5.9,0.1-9.7,4-9.9,9.9
   c-0.1,2.4,0,4.8,0,7.2c0,1.1,0,2.2,0,3.3l0,0c0,1.1,0,2.2,0,3.3c0,2.5,0,5,0,7.5c0.1,6.3,3.9,10.1,10,10.3c7.3,0.2,14.6,0.2,21.7,0
   c5.8-0.2,9.7-3.9,9.9-9.6c0.2-7.7,0.2-15.2,0-22.4c-0.2-5.7-4.1-9.4-10-9.5C156.4,184.8,152.7,184.8,149.1,184.8z M156.2,212.4
   h-13.7v-13.6h13.7V212.4z M143.5,211.4h11.7v-11.6h-11.7V211.4z"/>
			</g>
			<g>
				<path d="M232.8,179.9c14.7,0,29.5,0,44.2,0c5.7,0,8.8,2.7,8.8,7.4c0,4.5-3.2,7.3-8.6,7.3c-29.6,0-59.2,0-88.8,0
   c-5.5,0-8.7-3-8.6-7.7c0.1-4.5,3.3-7.1,8.7-7.1C203.3,179.9,218.1,179.9,232.8,179.9z"/>
				<path d="M233,195.2c-14.8,0-29.7,0-44.5,0c-3,0-5.5-0.9-7.1-2.5c-1.4-1.4-2.1-3.4-2-5.6c0.1-4.8,3.5-7.6,9.2-7.6
   c10.5,0,21.1,0,31.6,0l12.6,0l12.6,0c10.5,0,21,0,31.6,0c3.2,0,5.7,0.9,7.3,2.5c1.3,1.4,2,3.2,2,5.5c0,4.8-3.5,7.8-9.1,7.8
   C262.6,195.2,247.8,195.2,233,195.2z M207.7,180.4c-6.4,0-12.7,0-19.1,0c-5.2,0-8.1,2.4-8.2,6.6c-0.1,2,0.5,3.7,1.7,4.9
   c1.4,1.5,3.6,2.2,6.4,2.2c29.6,0,59.5,0,88.8,0c5,0,8-2.6,8.1-6.8c0-2-0.6-3.6-1.7-4.7c-1.4-1.4-3.7-2.2-6.6-2.2
   c-10.5,0-21.1,0-31.6,0l-12.6,0l-12.6,0C216,180.4,211.8,180.4,207.7,180.4z"/>
			</g>
			<g>
				<path d="M232.9,227c-14.4,0-28.7,0-43.1,0c-1.5,0-3,0-4.5-0.4c-3.6-0.8-5.7-3.8-5.5-7.4c0.1-3.5,2.7-6.4,6.2-6.9
   c1.1-0.2,2.2-0.1,3.4-0.1c29,0,57.9,0,86.9,0c1,0,2-0.1,3,0.1c3.6,0.5,6.2,3.2,6.4,6.7c0.3,3.8-1.9,6.8-5.7,7.7
   c-1.3,0.3-2.7,0.3-4.1,0.3C261.7,227,247.3,227,232.9,227z"/>
				<path d="M213.8,227.5c-8,0-16,0-23.9,0c-1.6,0-3.1,0-4.6-0.4c-3.8-0.9-6.1-4-5.9-7.9c0.2-3.8,2.9-6.8,6.6-7.4
   c0.9-0.1,1.8-0.1,2.7-0.1l0.7,0c29,0,57.9,0,86.9,0l0.7,0c0.8,0,1.6,0,2.4,0.1c3.8,0.5,6.6,3.4,6.9,7.2c0.3,4.1-2.1,7.3-6.1,8.2
   c-1.3,0.3-2.7,0.3-4.2,0.3c-11.3,0-22.5,0-33.8,0l-9.3,0l-9.6,0C220.2,227.5,217,227.5,213.8,227.5z M187.9,212.7
   c-0.6,0-1.2,0-1.8,0.1c-3.3,0.5-5.7,3.2-5.8,6.4c-0.1,3.4,1.9,6.1,5.2,6.9c1.3,0.3,2.8,0.3,4.3,0.3c11.2,0,22.3,0,33.5,0l9.6,0
   l9.3,0c11.3,0,22.5,0,33.8,0c1.5,0,2.8,0,4-0.3c3.4-0.8,5.5-3.6,5.3-7.2c-0.2-3.3-2.6-5.8-6-6.3c-0.7-0.1-1.5-0.1-2.2-0.1l-0.7,0
   c-29,0-57.9,0-86.9,0c-0.3,0-0.5,0-0.8,0C188.4,212.7,188.2,212.7,187.9,212.7z"/>
			</g>
			<g>
				<path d="M233,147.6c15,0,30-0.1,44.9,0c6.2,0,9.8,5.7,7,10.8c-1.8,3.3-4.9,3.9-8.3,3.9c-13.7,0-27.5,0-41.2,0
   c-15.6,0-31.2,0-46.8,0c-5.1,0-8.2-2.4-8.7-6.4c-0.6-4.9,2.7-8.3,8.2-8.3C203.1,147.6,218,147.6,233,147.6z"/>
				<path d="M276.7,162.8C276.7,162.8,276.6,162.8,276.7,162.8c-9.8,0-19.6,0-29.4,0l-24.3,0c-11.5,0-22.9,0-34.4,0
   c-5.3,0-8.7-2.5-9.2-6.8c-0.3-2.4,0.3-4.5,1.7-6.1c1.6-1.8,4-2.7,7-2.8c10.8,0,21.7,0,32.5,0l12.4,0c4.4,0,8.7,0,13.1,0
   c10.4,0,21.2,0,31.9,0c3.2,0,6,1.5,7.4,4c1.4,2.3,1.4,5.1,0,7.6C283.3,162.3,279.9,162.8,276.7,162.8z M258.6,161.8c6,0,12,0,18,0
   c3.1,0,6.1-0.4,7.9-3.7c1.2-2.2,1.2-4.6,0-6.6c-1.3-2.2-3.7-3.5-6.6-3.5c-10.6-0.1-21.4,0-31.9,0c-4.4,0-8.7,0-13.1,0l-12.4,0
   c-10.8,0-21.7,0-32.5,0c-2.6,0-4.8,0.9-6.2,2.4c-1.2,1.4-1.7,3.2-1.5,5.3c0.5,3.8,3.4,5.9,8.2,5.9c11.5,0,22.9,0,34.4,0l24.3,0
   C251.1,161.8,254.8,161.8,258.6,161.8z"/>
			</g>
			<g>
				<path d="M146.8,152.9c3-3.2,5.8-6.3,8.7-9.3c3.7-3.8,8.1-4.1,11.3-1c3.2,3.1,2.9,7.6-0.7,11.3c-4.6,4.6-9.1,9.2-13.8,13.8
   c-4.1,4-8,3.9-12.1-0.1c-2.1-2-4.1-4-6.1-6.1c-3.5-3.6-3.7-7.9-0.6-11c2.9-2.9,7.2-2.8,10.7,0.3C145,151.3,145.6,151.9,146.8,152.9
   z"/>
				<path d="M146.4,171.1c-2.1,0-4.3-1.1-6.5-3.2c-2.4-2.4-4.4-4.3-6.1-6.1c-3.7-3.9-3.9-8.3-0.6-11.7c3.2-3.2,7.6-3.1,11.4,0.2
   c0.4,0.4,0.8,0.7,1.4,1.2l0.8,0.7c0.8-0.9,1.6-1.7,2.4-2.6c2-2.2,4-4.3,6-6.4c3.9-3.9,8.6-4.3,12-1c1.5,1.5,2.3,3.3,2.4,5.3
   c0,2.3-1.1,4.7-3.1,6.7c-4.7,4.8-9.3,9.4-13.8,13.8C150.6,170.1,148.5,171.1,146.4,171.1z M138.5,148.8c-1.7,0-3.3,0.7-4.6,2
   c-2.9,2.9-2.7,6.9,0.6,10.3c1.7,1.8,3.6,3.7,6.1,6.1c3.9,3.8,7.5,3.8,11.4,0.1c4.4-4.4,9.1-9,13.8-13.8c1.8-1.8,2.8-4,2.8-6
   c0-1.7-0.7-3.3-2.1-4.6c-3-2.9-7-2.5-10.6,1c-2,2.1-4,4.2-6,6.4c-0.9,1-1.8,2-2.7,2.9l-0.3,0.4l-1.5-1.3c-0.5-0.5-0.9-0.8-1.4-1.2
   C142.2,149.6,140.4,148.8,138.5,148.8z"/>
			</g>
			<g>
				<path d="M206.9,284.9c-2,0-4,0.1-6,0c-3.8-0.3-6.5-3.1-6.8-6.7c-0.3-3.9,2.1-7.3,6-7.7c4.4-0.4,9-0.4,13.4,0c4,0.3,6.4,3.6,6.2,7.5
   c-0.2,3.8-3,6.7-6.9,6.9C210.9,285.1,208.9,284.9,206.9,284.9z"/>
				<path d="M210.6,285.5c-0.7,0-1.3,0-2,0c-0.6,0-1.2,0-1.8,0c-0.6,0-1.3,0-1.9,0c-1.3,0-2.7,0-4.1-0.1c-4-0.3-7-3.2-7.2-7.2
   c-0.3-4.4,2.4-7.8,6.5-8.2c4.4-0.4,8.9-0.4,13.5,0c4.1,0.3,6.9,3.6,6.7,8c-0.2,4.1-3.2,7.1-7.4,7.4
   C212.1,285.5,211.4,285.5,210.6,285.5z M206.7,284.4c0,0,0.1,0,0.1,0c0.6,0,1.2,0,1.8,0c1.4,0,2.8,0,4.1,0c3.7-0.2,6.3-2.8,6.5-6.5
   c0.2-3.8-2.2-6.7-5.8-7c-4.5-0.4-9-0.4-13.3,0c-3.5,0.3-5.8,3.3-5.6,7.1c0.2,3.4,2.8,6,6.3,6.3c1.3,0.1,2.7,0.1,4,0
   C205.5,284.5,206.1,284.4,206.7,284.4z"/>
			</g>
		</svg>

	);
}

export default SvgEmojiTransportation;
