import * as React from 'react';

function SvgVehicleIcon(props) {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            height='1em'
            width='1em'
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 413.2 400"
            className='svg-icon'
            {...props}
        >
            <path d="M398.6,256.5c-2.8,8.5-8.1,13.9-17.5,14.2c-4.2,0.1-8.4,0-13.2,0c-2.1,15.4-9.6,28.5-23.5,37.6c-9.6,6.3-20.2,8.8-31.7,8.2
c-23.6-1.3-40.3-17-47.9-45.6c-38.8,0-77.6,0-116.4,0c-5.1,32.1-31.6,46.3-52.4,45.8C70.2,316,52.5,300.1,45,270
c-12.9,1.8-25.4,2.4-30.4-13.5c0-5.5,0-11,0-16.5c2.8-7.3,7.4-12.5,15.8-13.7c0-12.2,0-24.2,0-36.1c0-4.6-0.1-9.2,0-13.8
c0.1-3.9,2.3-6.5,6.1-5.9c6.8,1,10.6-2.4,14.6-7.2c13.5-16,27.4-31.8,41.2-47.6c1.8-2.1,2.8-4.2,2.6-7c-0.2-3-0.1-6,0-9
c0.3-8.1,6.7-14.8,14.6-15.4c8.4-0.6,15.5,4.8,17,13.1c0.3,1.8,0.4,3.7,0.4,5.6c0,20.8,0,41.7,0,62.5c0,1.5,0,2.9,0,4.6
c3.3,0,6.3,0,9.8,0c0-6.2-0.2-12.3,0-18.3c0.5-12.4,13-20.5,24.4-15.6c7.1,3,10.9,8.6,11,16.4c0.1,11.5,0,23,0,34.4
c0,1.3,0,2.7,0,4.5c12.3-0.8,23.8,0.5,33.2,9.1c9.2,8.5,12.4,19.3,11.7,31.6c5.5,0,10.8,0.5,15.9-0.1c6.2-0.7,10.4-5.9,10.5-12.2
c0.1-7.1,0.1-14.2,0-21.3c0-1.1-0.5-2.6-1.3-3.2c-9-7.4-18-14.6-27.4-22.1c-2,3-4,5.8-5.9,8.6c-2.5,3.6-5.7,4.5-8.6,2.5
c-2.9-2-3.2-5.2-0.7-8.9c5.8-8.6,11.6-17.2,17.4-25.7c2.4-3.6,5.7-4.5,8.5-2.5c2.8,1.9,3.1,5.3,0.7,8.9c-1.6,2.5-3.3,4.9-5.1,7.7
c7.4,5.9,14.5,11.7,21.7,17.4c0.4-0.1,0.8-0.2,1.2-0.3c-0.6-6.2-0.1-12.7-1.9-18.5c-7.2-23.5-15-46.7-22.6-70.1
c-0.2-0.6-0.4-1.2-0.6-1.8c-1.4-5,1-8.2,6.2-8c14.4,0.7,24.9,7.7,31.1,20.6c8.4,17.5,16.7,35.1,24.8,52.8c1.4,3.1,3.1,4,6.4,4
c10.1-0.2,20.2-0.1,30.4-0.1c4.7,0,7.3,2.1,7.3,5.6c0,3.5-2.7,5.6-7.4,5.6c-12.2,0-24.5-0.1-36.7,0.1c-3.8,0-6-1.5-7.5-4.9
c-8.9-19.2-18-38.4-27-57.5c-2.8-6-7-10.5-13.8-13.4c0.4,1.5,0.5,2.4,0.8,3.4c6.7,20.6,13.4,41.2,20,61.9c0.8,2.4,1.2,5.1,1.2,7.7
c0.1,16.6,0.1,33.2,0,49.8c0,15.7-10,25.6-25.9,25.6c-20.1,0-40.2,0-60.4,0c-14.5,0-23.7-8-25.8-22.3c-1-6.5-1.8-13.1-2.7-19.6
c-0.9-6.5-1.7-13.1-2.6-19.9c-31.8,0-63.4,0-95.4,0c0,14.7,0,29.5,0,43.5c4.5,3.1,8.7,6,13.2,9.2c0.5-0.6,1.5-1.9,2.7-3.2
c13.8-15.1,30.8-20.5,50.9-16.3c4.8,1,6.8,3.5,6.1,6.9c-0.8,3.7-3.7,5-8.6,3.9c-18.5-4-36.8,4.4-45.2,20.7
c-8.5,16.5-4.8,36.1,9,48.5c15.8,14.2,40.3,13.6,55.4-1.5c15-15,15.8-39.2,1.9-55c-0.7-0.8-1.6-1.6-2.3-2.5c-2.2-2.7-2.2-5.9,0.1-8
c2.3-2.2,5.5-2.1,8,0.4c7.5,7.4,12.3,16.2,14.4,26.6c0.3,1.3,0.5,2.7,0.8,4.2c38.9,0,77.7,0,116.7,0c4.4-23.6,17.7-39.7,41.8-44.6
c21.2-4.3,38.5,3.6,52.1,20.7c3.7-5.4,8.6-8.9,15.5-9.1c0-8.9,0.2-17.5-0.1-26.1c-0.2-4.4-3.4-6.7-8.4-6.7c-26.1,0-52.2,0-78.4,0
c-1.7,0-3.5,0-5.2,0c-4.4-0.1-6.8-2.2-6.7-5.8c0.1-3.4,2.4-5.4,6.7-5.4c24.9,0,49.7,0,74.6,0c1.3,0,2.7,0,4.4,0c0-2.2,0.1-4,0-5.8
c-0.2-3.4-2.1-5.2-5.5-5.3c-4.2-0.1-8.5,0-12.7,0c-4.2-0.1-6.6-2.1-6.6-5.5c-0.1-3.6,2.4-5.7,6.8-5.7c4.2,0,8.5-0.1,12.7,0
c9.4,0.1,16.3,6.9,16.6,16.2c0,1.1,0.1,2.3,0,3.4c-0.3,2.4,0.5,3.7,2.8,4.9c7,3.5,10.3,9.6,10.3,17.3c0.1,7.4,0.1,14.7,0,22.1
c0,2.1,0.5,3.3,2.6,4.2c5.5,2.1,8.3,6.9,10.3,12C398.6,245.5,398.6,251,398.6,256.5z M276.2,265.2c0,22.3,18,40.1,40.3,40.2
c22.3,0,40.3-17.8,40.3-40.1c0.1-22.4-18-40.4-40.4-40.3C294,225,276.1,242.9,276.2,265.2z M205.4,232.4c2.4-17-8.5-29.7-25.2-29.8
c-4,0-8,0-12,0c-5.3,0-7.3-1.9-7.3-7.2c0-9.7,0-19.5,0-29.2c0-4.6,0.1-9.2,0-13.8c-0.1-3.1-1.7-5.2-4.7-6c-3-0.9-5.6,0-7.1,2.7
c-0.8,1.4-1,3.3-1.1,5c-1.3,23,3.3,45.4,6.2,68c0.8,5.8,5.6,10.2,11.7,10.3C178.9,232.6,192.1,232.4,205.4,232.4z M115.4,170.1
c0.1-0.9,0.2-1.6,0.2-2.4c0-22.1,0-44.1,0-66.2c0-1.1,0-2.5-0.6-3.2c-1.1-1.2-2.7-2.7-4-2.6c-1.5,0.1-3.2,1.4-4.4,2.7
c-0.7,0.7-0.5,2.4-0.5,3.6c0,21.6,0,43.1,0,64.7c0,1.1,0.1,2.2,0.2,3.4C109.4,170.1,112.3,170.1,115.4,170.1z M60,170
c12.1,0,23.4,0,34.6,0c0-13.2,0-26.2,0-40C82.9,143.6,71.7,156.5,60,170z M25.9,248.6C25.9,248.6,25.9,248.6,25.9,248.6
c0,1.2,0,2.5,0,3.7c0.1,4,1.9,6.5,5.3,7.1c7,1.3,12.7,0.1,13.2-3.7c0.7-5,0.9-10.6-0.7-15.2c-1.6-4.6-7.2-2.4-11.1-2.8
c-0.2,0-0.5,0-0.7,0c-3.5,0.4-5.7,2.5-5.9,6C25.8,245.3,25.9,246.9,25.9,248.6z M387.4,248.7c0-10.2-1.1-11.3-11.4-11.1
c-0.5,0-1,0-1.5,0c-3.8,0.3-6.1,2.3-6.3,6.1c-0.2,3.2-0.2,6.5,0,9.7c0.2,3.5,2.6,5.7,6,6.1c8,0.8,11.7-0.5,13-4.5
c0.3-0.8,0.3-1.7,0.3-2.6C387.4,251.2,387.4,250,387.4,248.7z"/>
            <path d="M124.1,265.2c0,15.1-12.5,27.3-27.6,27.2c-14.9-0.1-27.1-12.5-27.1-27.3c0-15,12.5-27.4,27.6-27.2
C111.9,238,124.1,250.3,124.1,265.2z M112.8,265c-0.1-8.7-7.3-15.9-16-15.9c-8.9,0-16.2,7.4-16.1,16.2c0.1,8.7,7.3,15.9,16,15.9
C105.6,281.2,112.9,273.9,112.8,265z"/>
            <path d="M87.8,200.3c-10.6,0-21.2,0-31.9,0c-4.7,0-7.4-2-7.4-5.5c-0.1-3.5,2.6-5.7,7.2-5.7c21.4,0,42.7,0,64.1,0
c4.4,0,7,2.1,7.1,5.5c0.1,3.6-2.6,5.7-7.2,5.7C109,200.4,98.4,200.3,87.8,200.3z"/>
            <path d="M355.1,212.7c0-2.1-0.1-4.2,0-6.3c0.3-3.2,2.1-5,5.3-5.2c3.2-0.2,5.4,1.7,5.7,4.7c0.4,4.4,0.4,9,0,13.4
c-0.2,3.1-2.5,4.8-5.6,4.9c-2.9,0.1-5.2-2.3-5.4-5.5C355,216.6,355.1,214.6,355.1,212.7z"/>
            <path d="M289.1,265.1c0-14.9,12.3-27.2,27.2-27.3c15.1-0.1,27.5,12.3,27.5,27.3c0,15-12.5,27.3-27.6,27.2
C301.3,292.3,289.1,280,289.1,265.1z M316.4,281.2c8.7,0,16-7.1,16.1-15.9c0.1-8.8-7.2-16.3-16.1-16.3c-8.7,0-16,7.2-16.1,15.9
C300.3,273.8,307.5,281.2,316.4,281.2z"/>
        </svg>
    );
}

export default SvgVehicleIcon;
