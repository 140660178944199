import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-text-mask';
import * as api from '../../../../api';
import {
	REGISTRATION_TYPE_SUPERADMIN,
	USER_ROLE,
	REGISTRATION_TYPE_PARTNER,
} from '../../../../constants';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import Select from '../../../../components/bootstrap/forms/Select';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import {
	setAlert,
	setLoading,
	setResidentPreviewPage,
} from '../../../../globalState/action-creators';

const AddResident = () => {
	const user = useSelector((state) => state?.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const token = sessionStorage.getItem('token');
	const [unitNumberData, setUnitNumberData] = useState([]);
	const [partnerData, setPartnerData] = useState([]);

	const typeLists =
		user?.role === USER_ROLE.SUPER_ADMIN
			? REGISTRATION_TYPE_SUPERADMIN
			: REGISTRATION_TYPE_PARTNER;

	useEffect(() => {
		if (!token) {
			navigate('/auth-pages/login', { replace: true });
			return;
		}

		if (user?.role === USER_ROLE.SUPER_ADMIN) {
			fetchAllPartnerNames();
		}
		// eslint-disable-next-line
	}, []);

	const fetchAllUnitNumbers = async (email) => {
		try {
			const { data } = await api.getUnitNumbers({ email });
			if (data?.success) {
				const unitData = data.data.map((item) => {
					return {
						...item,
						value: item?.unitNumber,
						text: item?.unitNumber,
					};
				});
				setUnitNumberData(unitData);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const fetchAllPartnerNames = async () => {
		try {
			const { data } = await api.getAllPartnerNames();
			if (data?.success) {
				const partnerData = data.data.map((item) => {
					return {
						...item,
						value: item?.partnerName,
						text: item?.partnerName,
					};
				});
				setPartnerData(partnerData);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const phoneNumberMask = [
		'(',
		/[1-9]/,
		/\d/,
		/\d/,
		')',
		' ',
		/\d/,
		/\d/,
		/\d/,
		'-',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	];

	const [residentNameError, setResidentNameError] = useState(false);
	const [contactNumberError, setContactNumberError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [licensePlateError, setLicensePlateError] = useState(false);
	const [makeError, setMakeError] = useState(false);
	const [modelError, setModelError] = useState(false);
	const [residentTypeError, setResidentTypeError] = useState(false);
	const [moveInDateError, setMoveInDateError] = useState(false);
	const [unitsError, setUnitsError] = useState(false);
	const [partnerNameError, setPartnerNameError] = useState(false);

	const today = new Date();
	const [formData, setFormData] = useState({
		unitId: '',
		partnerEmail: '',
		partnerName: '',
		residentName: '',
		email: '',
		phoneNumber: '',
		licensePlate: '',
		units: '',
		model: '',
		make: '',
		residentType: '',
		MoveInDate: '',
		leaseEndDate: '',
	});

	const createResident = async () => {
		dispatch(setLoading(true));
		try {
			const regex =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			let error = false;
			const reDigit = /^[0-9]*$/;

			if (_.get(formData, 'residentName', '')?.length <= 0) {
				error = true;
				setResidentNameError(true);
			}
			if (_.get(formData, 'make', '')?.length <= 0) {
				error = true;
				setMakeError(true);
			}
			if (_.get(formData, 'model', '')?.length <= 0) {
				error = true;
				setModelError(true);
			}
			if (_.get(formData, 'residentType', '')?.length <= 0) {
				error = true;
				setResidentTypeError(true);
			}
			if (_.get(formData, 'MoveInDate', '')?.length <= 0) {
				error = true;
				setMoveInDateError(true);
			}

			if (
				_.get(formData, 'licensePlate', '')?.length <= 0
				// || !reDigit.test(_.get(formData, 'licensePlate', ''))
			) {
				error = true;
				setLicensePlateError(true);
			}
			if (
				_.get(formData, 'units', '')?.length <= 0 
			) {
				error = true;
				setUnitsError(true);
			}
			if (
				user?.role === USER_ROLE.SUPER_ADMIN &&
				_.get(formData, 'partnerName', '')?.length <= 0
			) {
				error = true;
				setPartnerNameError(true);
			}
			if (
				_.get(formData, 'email', '')?.length <= 0 ||
				!regex.test(_.get(formData, 'email', ''))
			) {
				error = true;
				setEmailError(true);
			}
			if (_.get(formData, 'MoveInDate', '')?.length > 0) {
				const moveInDate = new Date(formData.MoveInDate);
				const yesterday = new Date();
				yesterday.setDate(yesterday.getDate() - 1);
				if (moveInDate < yesterday) {
					error = true;
					setMoveInDateError(true);
				}
			}

			if (
				(_.get(formData, 'phoneNumber', '').length > 0 &&
					_.get(formData, 'phoneNumber', '')?.replace(/[^\d]/g, '').length < 10) ||
				_.get(formData, 'phoneNumber', '').length <= 0
			) {
				error = true;
				setContactNumberError(true);
			}

			formData.phoneNumber =
				_.get(formData, 'phoneNumber', '').length > 0
					? _.get(formData, 'phoneNumber', '')?.replace(/[^\d]/g, '')
					: '';

			if (!error) {
				const { data } = await api.addResidents(formData);
				if (data?.success) {
					dispatch(setResidentPreviewPage({ residentPage: 1 }));
					navigate('/residents');
					dispatch(setAlert(data?.message, 'Success'));
				} else {
					dispatch(setAlert(data?.message, 'Error'));
				}
			}
		} catch (error) {
			dispatch(setAlert(error?.message, 'Error'));
		}
		dispatch(setLoading(false));
	};

	const onChange = (e) => {
		const { name, value } = e.target;
		if (name === 'residentName') {
			setResidentNameError(false);
		}
		if (name === 'phoneNumber') {
			setContactNumberError(false);
		}
		if (name === 'email') {
			setEmailError(false);
		}
		if (name === 'licensePlate') {
			setLicensePlateError(false);
		}
		if (name === 'make') {
			setMakeError(false);
		}
		if (name === 'model') {
			setModelError(false);
		}
		if (name === 'residentType') {
			setResidentTypeError(false);
		}
		if (name === 'units') {
			setUnitsError(false);
		}
		if (name === 'partnerName') {
			setPartnerNameError(false);
		}
		if (name === 'MoveInDate') {
			setMoveInDateError(false);
		}

		if (name === 'residentType' && value === 'Homeowner Admin') {
			setFormData({ ...formData, [name]: value, leaseEndDate: '' });
		} else {
			setFormData({ ...formData, [name]: value });
		}
	};

	useEffect(() => {
		if (formData?.partnerEmail) {
			fetchAllUnitNumbers(formData?.partnerEmail);
		}
		if (user?.role === USER_ROLE.PARTNER) {
			fetchAllUnitNumbers(user?.email);
		}
	}, [formData?.partnerEmail, user]);

	return (
		<PageWrapper title='Add resident'>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xxl-6'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='PersonAdd' iconColor='dark'>
									<CardTitle tag='h4' className='h5'>
										Create Resident
									</CardTitle>
								</CardLabel>
							</CardHeader>

							<CardBody>
								<div className='row'>
									<div className='create_vehicle_inner'>
										<div className='create-vehicle-wrapper add_vehicle'>
											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Name{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														id='residentName'
														className='form-control addVehicleSelect'
														autoComplete='off'
														onChange={(e) => {
															onChange(e);
														}}
														onKeyDown={(event) => {
															if (!/^[a-zA-Z\s]+$/.test(event.key)) {
																event.preventDefault();
															}
														}}
														name='residentName'
													/>
												</div>
												<span className='text-danger danger-msg'>
													{residentNameError
														? 'Resident name required!'
														: ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Phone Number{' '}
														<span className='text-danger'>*</span>
													</p>
												</div>
												<div className='data'>
													<InputMask
														guide={false}
														type='text'
														value={_.get(formData, `phoneNumber`, '')}
														// keepCharPositions={false}
														mask={phoneNumberMask}
														className='form-control addVehicleSelect delete_form_input addpartner_withoutbtn'
														name='phoneNumber'
														onChange={(e) => onChange(e)}
													/>
												</div>
												<span className='text-danger danger-msg'>
													{contactNumberError
														? 'Enter a valid phone number!'
														: ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Email{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														id='email'
														className='form-control addVehicleSelect'
														autoComplete='off'
														onChange={(e) => {
															onChange(e);
														}}
														name='email'
													/>
												</div>
												<span className='text-danger danger-msg'>
													{emailError ? 'Invalid Email!' : ''}
												</span>
											</div>

											{user?.role === USER_ROLE.SUPER_ADMIN && (
												<div className='card-info-item card-inner'>
													<div className='label'>
														<p className='mx-3 lable_spacing'>
															Partner Name{' '}
															<span className='text-danger fw-bold'>
																*
															</span>
														</p>
													</div>
													<div className='data'>
														<Select
															ariaLabel='Default select example'
															placeholder=' '
															id='partnerName'
															name='partnerName'
															value={formData?.partnerName}
															onChange={(e) => {
																onChange(e);
																const selectedValue =
																	e.target.value;
																const selectedItem =
																	partnerData.find(
																		(item) =>
																			item.value ===
																			selectedValue,
																	);
																setFormData((prevFormData) => ({
																	...prevFormData,
																	partnerEmail:
																		selectedItem?.email || '',
																}));
															}}
															list={partnerData}
															className='addVehicleSelect inputBoxShadow'
														/>
													</div>
													<span className='text-danger danger-msg'>
														{partnerNameError
															? 'Partner required!'
															: ''}
													</span>
												</div>
											)}

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Resident Type
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<Select
														ariaLabel='Default select example'
														placeholder=' '
														id='residentType'
														name='residentType'
														value={formData?.residentType}
														onChange={(e) => {
															onChange(e);
														}}
														list={typeLists}
														className='addVehicleSelect inputBoxShadow'
													/>
												</div>
												<span className='text-danger danger-msg'>
													{residentTypeError
														? 'Select Resident Type.'
														: ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														License Plate{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														id='licenePlate'
														className='form-control addVehicleSelect no-arrows'
														autoComplete='off'
														name='licensePlate'
														onChange={(e) => {
															onChange(e);
														}}
														onKeyDown={(e) => {
															const key = e.key;
															const isSpecialCharacter =
																/[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/;

															if (
																key === ' ' ||
																(isSpecialCharacter.test(key) &&
																	key !== '-')
															) {
																e.preventDefault();
															}
														}}
													/>
												</div>
												<span className='text-danger danger-msg'>
													{licensePlateError
														? 'License Plate required!'
														: ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Unit{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<Select
														ariaLabel='Default select example'
														placeholder=' '
														id='units'
														name='units'
														value={formData?.units}
														onChange={(e) => {
															onChange(e);
															const selectedValue = e.target.value;
															const selectedItem =
																unitNumberData.find(
																	(item) =>
																		item.value ===
																		selectedValue,
																);
															setFormData((prevFormData) => ({
																...prevFormData,
																unitId: selectedItem?._id || '',
															}));
														}}
														list={
															unitNumberData?.length > 0
																? unitNumberData
																: [
																		{
																			value: '',
																			text: 'No Space Available',
																		},
																  ]
														}
														className='addVehicleSelect inputBoxShadow'
													/>
												</div>
												<span className='text-danger danger-msg'>
													{unitsError ? 'Unit required!' : ''}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Make{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														className='form-control addVehicleSelect'
														autoComplete='off'
														onChange={(e) => {
															onChange(e);
														}}
														name='make'
													/>
												</div>
												<span
													id='licensePlatesStateSpan'
													className='text-danger danger-msg'
												>
													{makeError && 'Please enter a make!'}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Model
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														className='form-control addVehicleSelect no-arrows'
														autoComplete='off'
														name='model'
														onChange={(e) => {
															onChange(e);
														}}
														onKeyDown={(e) => {
															if (e.key === ' ') {
																e.preventDefault();
															}
														}}
													/>
												</div>
												<span
													id='licensePlatesStateSpan'
													className='text-danger danger-msg'
												>
													{modelError && 'Please enter a model!'}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Move in Date{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<DatePicker
														className='form-control addVehicleSelect input_feild'
														clearIcon={null}
														name='MoveInDate'
														onChange={(e) => {
															const formattedDate = e
																? moment(e).format('MM/DD/yyyy')
																: '';
															setFormData({
																...formData,
																MoveInDate: formattedDate,
															});
															setMoveInDateError(false);
														}}
														format='MM/dd/yyyy'
														value={
															formData?.MoveInDate &&
															formData?.MoveInDate?.length > 0
																? new Date(formData?.MoveInDate)
																: ''
														}
														minDate={today}
													/>
												</div>
												<span className='text-danger danger-msg'>
													{moveInDateError
														? 'Move in date required!'
														: ''}
												</span>
											</div>

											{formData?.residentType === 'Homeowner Resident' && (
												<div className='card-info-item card-inner'>
													<div className='label'>
														<p className='mx-3 lable_spacing'>
															LEASE END DATE{''}
														</p>
													</div>
													<div className='data'>
														<DatePicker
															className='form-control addVehicleSelect input_feild'
															clearIcon={null}
															name='leaseEndDate'
															onChange={(e) => {
																const formattedDate = e
																	? moment(e).format('MM/DD/yyyy')
																	: '';
																setFormData({
																	...formData,
																	leaseEndDate: formattedDate,
																});
															}}
															format='MM/dd/yyyy'
															value={
																formData?.leaseEndDate &&
																formData?.leaseEndDate?.length > 0
																	? new Date(
																			formData?.leaseEndDate,
																	  )
																	: ''
															}
															minDate={
																formData.MoveInDate
																	? new Date(formData.MoveInDate)
																	: today
															}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>

								<Button className='mx-2 mt-3' color='dark' onClick={createResident}>
									Save
								</Button>
								<Button
									className='mt-3'
									color='danger'
									onClick={() => navigate('/residents?tab=active')}
								>
									Cancel
								</Button>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default AddResident;
