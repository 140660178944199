import * as React from 'react';

function SvgHoldMoke(props) {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            height="16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink" 
            x="0px"
            y="0px"
            viewBox="0 0 413.9 413.9"
            style={{ enableBackground: "new 0 0 413.9 413.9" }}
            {...props}
        //    xml:space="preserve"
        >
            <path d="M84,197.4c0-22.2,0-43.5,0-64.9c0-14.6,0.3-29.3-0.1-43.9c-0.6-25.5,24.7-41.5,46.3-31.9c0.4,0.2,0.9,0.2,1.9,0.5
	c-0.9-12.5,2.1-23.5,12-31.7c6.5-5.4,14.1-8,22.6-7.5c11.1,0.6,19.7,5.7,26,14.8C199,42,198,52.5,198,63.1c3.1-0.9,5.9-1.9,8.8-2.5
	c20.3-3.9,38.9,11.3,39.2,31.9c0.1,6.1,0,12.2,0,18.6c3-0.9,5.6-1.9,8.4-2.4c20.7-4.1,39.5,11.1,39.6,32.2c0.1,29.8,0,59.5,0,89.3
	c0,1.4,0,2.7,0,4.4c11.6-2,22.8-1.6,34,0.9c32.1,7.3,57.9,36.3,61.3,68.9c4.6,44.3-23.6,82-67.3,89.8c-17.1,3-33.6,0.2-49.2-7.5
	c-1.9-0.9-3.2-1-5.1,0.2c-9.6,6.3-20.1,9.2-31.5,9.2c-47,0.1-94,0.1-141,0.2c-4.7,0-8-1.6-10.9-5.2c-14.1-17.4-28.2-34.8-42.5-52
	c-8.2-9.9-12.1-20.9-12-33.8c0.2-35.9,0.1-71.8,0.1-107.7c0-8.3,3.1-11.5,11.5-11.7C56.5,185.7,70.7,188.4,84,197.4z M254.1,375
	c0.2-0.6,0.4-1.2,0.6-1.8c-1.2-0.8-2.5-1.3-3.4-2.3c-18.5-19.5-26.3-42.7-22.1-69.1c4.4-27.5,19.6-47.6,44.5-60.3
	c1-0.5,2.3-1.6,2.3-2.4c0.2-7.6,0.1-15.2,0.1-22.8c-58.2,0-116,0-173.8,0c0,16.3,0,32.4,0,48.4c1.1,0.2,2,0.4,2.8,0.5
	c0.7,0.1,1.5,0.2,2.2,0.3c27.4,5.2,45.4,22.3,58,46c2.2,4.1,0.2,9.1-3.8,11.3c-4.1,2.3-8.9,1.2-11.6-2.6c-1.1-1.5-1.9-3.2-2.9-4.8
	c-12.2-20.3-29.3-32.8-53.8-33.3c-5.9-0.1-9.2-3.7-9.2-9.6c-0.1-12.4,0.1-24.8-0.1-37.1c-0.2-9.8-3.3-18.4-11.8-24.3
	c-7.1-4.9-15.3-6.5-24.1-7.1c0,1.6,0,3,0,4.3c0,32.8,0.1,65.5,0,98.3c0,7.7,2.4,14.4,7.3,20.3c13.4,16.3,26.7,32.7,40.2,49
	c0.9,1.1,2.8,2.2,4.3,2.2c46.3,0.1,92.5,0.1,138.8-0.2C243.7,377.9,248.8,376,254.1,375z M371.2,305.3
	c-2.7-26.3-27.8-51.4-53.1-53.1c0,2.5,0.1,5,0,7.5c-0.1,5.6-3.8,9.5-8.9,9.6c-5.1,0-8.9-3.9-9-9.5c-0.1-2.5,0-5.1,0-7.7
	c-31.5,4.4-51.3,31.4-52.9,53.2c2.5,0,5-0.1,7.5,0c5.5,0.2,9.5,4,9.4,9.1c-0.1,5-3.9,8.7-9.3,8.8c-2.6,0.1-5.2,0-7.8,0
	c2.6,26.3,27.6,51.2,53.1,53.1c0-2.5,0-5,0-7.5c0.1-5.6,3.8-9.5,8.9-9.6c5.1,0,8.9,3.9,9,9.5c0.1,2.5,0,5.1,0,7.7
	c32.2-4.5,51.5-32.2,52.9-53.2c-2.5,0-5,0.1-7.5,0c-5.5-0.2-9.4-4-9.4-9.1c0.1-5,3.9-8.7,9.3-8.8
	C365.9,305.3,368.5,305.3,371.2,305.3z M180,197.8c0-1.6,0-3.1,0-4.6c0-38.3,0-76.5,0-114.8c0-9.1,0.1-18.3,0-27.4
	c-0.1-6.7-4.1-12.3-10.1-14.3c-10-3.4-19.8,3.9-19.8,14.9c0,47.8,0,95.5,0,143.3c0,0.9,0.2,1.9,0.3,2.8
	C160.2,197.8,169.9,197.8,180,197.8z M132,197.8c0-1.5,0-2.9,0-4.2c0-13.1,0-26.3,0-39.4c0-22.1,0-44.3,0-66.4
	c0-7.3-3.9-12.9-10.2-15.1c-10-3.4-19.8,3.9-19.8,14.9c0,35.8,0,71.5,0,107.3c0,0.9,0.2,1.9,0.3,2.8
	C112.2,197.8,121.9,197.8,132,197.8z M198.1,197.7c10.2,0,20,0,29.9,0c0-7.7,0-15,0-22.4c0-27.1,0-54.3,0-81.4
	c0-7.3-3.8-13-10.1-15.1c-10-3.4-19.9,3.8-19.9,14.9c0,33.9,0,67.8,0,101.7C198,196,198.1,196.8,198.1,197.7z M276,197.8
	c0-1.3,0-2.4,0-3.5c0-17.3,0-34.5,0-51.8c0-9.8-6.1-16.6-14.9-16.6c-8.8,0-15,6.7-15.1,16.5c0,17.3,0,34.5,0,51.8
	c0,1.2,0.1,2.4,0.2,3.6C256.3,197.8,266,197.8,276,197.8z"/>
            <path d="M309.4,323.8c-2.1-0.7-3.8-1.2-5.3-1.9c-7.4-3.6-14.7-7.3-22.1-11c-5.4-2.7-7.3-7.6-5.1-12.3c2.3-4.9,7.5-6.4,13-3.8
	c4.9,2.4,9.9,4.8,14.7,7.4c2,1.1,3.2,0.9,4.8-0.8c5.5-5.7,11.2-11.4,16.9-17c4.4-4.3,10.3-3.9,13.8,0.5c2.7,3.5,2.7,8.2-0.6,11.6
	c-8,8.2-16.1,16.3-24.4,24.3C313.6,322.3,311.2,322.8,309.4,323.8z"/>
        </svg>

    );
}

export default SvgHoldMoke;
