import React from 'react';
import { componentsMenu } from '../menu';
import DashboardHeader2 from '../pages/common/Headers/DashboardHeader2';
import DashboardBookingHeader from '../pages/common/Headers/DashboardBookingHeader';
import ProfilePageHeader from '../pages/common/Headers/ProfilePageHeader';
import ProductsHeader from '../pages/common/Headers/ProductsHeader';
import ProductListHeader from '../pages/common/Headers/ProductListHeader';
import PageLayoutHeader from '../pages/common/Headers/PageLayoutHeader';
import ComponentsHeader from '../pages/common/Headers/ComponentsHeader';
import ChartsHeader from '../pages/common/Headers/ChartsHeader';
import ContentHeader from '../pages/common/Headers/ContentHeader';
import UtilitiesHeader from '../pages/common/Headers/UtilitiesHeader';
import IconHeader from '../pages/common/Headers/IconHeader';
import ExtrasHeader from '../pages/common/Headers/ExtrasHeader';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';

const headers = [
	{ path: 'page-layouts/only-subheader', element: null, exact: true },
	{ path: 'page-layouts/only-content', element: null, exact: true },
	{ path: 'page-layouts/blank', element: null, exact: true },
	{ path: 'auth-pages/login', element: null, exact: true },
	{ path: 'auth-pages/sign-up', element: null, exact: true },

	{ path: '/', element: <DashboardHeader2 />, exact: true },

	/**
	 * Units
	 */

	{
		path: 'Unit',
		element: <DashboardHeader2 />,
		exact: true,
	},
	{
		path: `Unit/create`,
		element: null,
		exact: true,
	},
	{
		path: `Unit/edit/:id`,
		element: null,
		exact: true,
	},
	{
		path: `Unit/detail/:id`,
		element: null,
		exact: true,
	},
	/**
	 * Residents
	 */
	{
		path: `residents`,
		element: <DashboardHeader2 />,
		exact: true,
	},
	{
		path: `residents/detail/:id`,
		element: null,
		exact: true,
	},
	{
		path: `residents/edit/:id`,
		element: null,
		exact: true,
	},
	{
		path: `residents/create`,
		element: null,
		exact: true,
	},

	/**
	 * Partners
	 */
	{
		path: `partners`,
		element: <DashboardHeader2 />,
		exact: true,
	},

	{
		path: `partners/detail/:id`,
		element: null,
		exact: true,
	},
	{
		path: `partners/edit/:id`,
		element: null,
		exact: true,
	},
	{
		path: `partners/create`,
		element: null,
		exact: true,
	},
	/**
	 * Guests
	 */
	{
		path: 'guests',
		element: <DashboardHeader2 />,
		exact: true,
	},
	{
		path: `guests/create`,
		element: null,
		exact: true,
	},
	{
		path: `guests/detail/:id`,
		element: null,
		exact: true,
	},
	{
		path: 'guests/edit/:id',
		element: null,
		exact: true,
	},
	/**
	 * Profile
	 */
	{
		path: 'profile',
		element: null,
		exact: true,
	},

	/**
	 * Other Pages
	 */

	{
		path: 'list-pages/fluid-list',
		element: <DashboardBookingHeader />,
		exact: true,
	},

	{
		path: 'single-pages/fluid',
		element: <ProfilePageHeader />,
		exact: true,
	},
	{
		path: 'single-pages/boxed',
		element: <ProfilePageHeader />,
		exact: true,
	},

	/**
	 * Vehicles
	 */
	{
		path: 'vehicles',
		element: <DashboardHeader2 />,
		exact: true,
	},
	{
		path: 'vehicles/create',
		element: null,
		exact: true,
	},
	{
		path: 'vehicles/edit/:id',
		element: null,
		exact: true,
	},
	{
		path: 'vehicles/detail/:id',
		element: null,
		exact: true,
	},

	/**
	 * Revenue
	 */
	{
		path: 'revenue',
		element: <DashboardHeader2 />,
		exact: true,
	},
	{
		path: 'revenue/detail/:id',
		element: null,
		exact: true,
	},

	/**
	 * Reservations
	 */
	{
		path: 'reservation',
		element: <DashboardHeader2 />,
		exact: true,
	},
	{
		path: `reservation/update/:id`,
		element: null,
		exact: true,
	},
	{
		path: `reservation/detail/:id`,
		element: null,
		exact: true,
	},

	/**
	 * Rates
	 */
	{
		path: `rates`,
		element: null,
		exact: true,
	},
	{
		path: `rates/change_price`,
		element: null,
		exact: true,
	},
	{
		path: `change_price/edit/:id/:index`,
		element: null,
		exact: true,
	},
	{
		path: `change_price/edit/per_hour/:id/:index`,
		element: null,
		exact: true,
	},
	{
		path: 'change_price/detail/:id',
		element: null,
		exact: true,
	},
	{
		path: `change_price`,
		element: null,
		exact: true,
	},

	/**
	 * CHATBOT
	 */

	{
		path: '/chatbot/renter',
		element: null,
		exact: true,
	},
	{
		path: '/chatbot',
		element: null,
		exact: true,
	},
	{
		path: '/chatbot/delivery',
		element: null,
		exact: true,
	},
	{
		path: '/chatbot/slots',
		element: null,
		exact: true,
	},

	/**
	 * Content
	 */
	{
		path: 'grid-pages/boxed',
		element: <ProductsHeader />,
		exact: true,
	},
	{
		path: 'grid-pages/fluid',
		element: <ProductsHeader />,
		exact: true,
	},
	{
		path: 'list-pages/boxed-list',
		element: <ProductListHeader />,
		exact: true,
	},

	{
		path: 'aside-types/*',
		element: <PageLayoutHeader />,
		exact: true,
	},
	{
		path: 'page-layouts/header-and-subheader',
		element: <PageLayoutHeader />,
		exact: true,
	},
	{
		path: 'page-layouts/only-header',
		element: <PageLayoutHeader />,
		exact: true,
	},
	{
		path: `${componentsMenu.components.path}/*`,
		element: <ComponentsHeader />,
	},
	{
		path: `${componentsMenu.charts.path}/*`,
		element: <ChartsHeader />,
	},
	{
		path: `${componentsMenu.content.path}/*`,
		element: <ContentHeader />,
	},
	{
		path: `${componentsMenu.utilities.path}/*`,
		element: <UtilitiesHeader />,
	},
	{
		path: `${componentsMenu.icons.path}/*`,
		element: <IconHeader />,
	},
	{
		path: `${componentsMenu.extra.path}/*`,
		element: <ExtrasHeader />,
	},
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
