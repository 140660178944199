import React from 'react';
import Header from '../../../layout/Header/Header';

const ProductListHeader = () => {
	return (
		<Header>
		</Header>
	);
};

export default ProductListHeader;
