import * as React from 'react';

function SvgDashboard(props) {
	return (
		<>
			{/* <svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			className='svg-icon'
			{...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path d='M5 5h4v6H5zm10 8h4v6h-4zM5 17h4v2H5zM15 5h4v2h-4z' opacity={0.3} />
			<path d='M3 13h8V3H3v10zm2-8h4v6H5V5zm8 16h8V11h-8v10zm2-8h4v6h-4v-6zM13 3v6h8V3h-8zm6 4h-4V5h4v2zM3 21h8v-6H3v6zm2-4h4v2H5v-2z' />
		</svg> */}

			{/* <svg
				xmlns="http://www.w3.org/2000/svg"
				height="16"
				viewBox="0 0 16 16"
				width="16"
				fill="currentColor"
				className="bi bi-columns-gap"
				{...props} >
				<path d="M6 1v3H1V1h5zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm14 12v3h-5v-3h5zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5zM6 8v7H1V8h5zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H1zm14-6v7h-5V1h5zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1h-5z" />
			</svg> */}

			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="16"
				x="0px"
				y="0px"
				viewBox="0 0 413.9 413.9"
				width="16"
				version="1.1"
				id="Layer_1"
				// xmlns:xlink="http://www.w3.org/1999/xlink"
				style={{
					enableBackground: "new 0 0 413.9 413.9",
				}}
				{...props}
			// xml:space="preserve"
			>
				<path  d="M110.7,191c-20.5,0-41,0-61.5,0c-11.9,0-18.4-6.4-18.4-18.2c0-41.2,0-82.5,0-123.7c0-11.7,6.4-18.2,18-18.2
	c41.4,0,82.7,0,124.1,0c11.4,0,18,6.5,18,17.8c0,41.5,0,83,0,124.4c0,11.3-6.6,17.8-18,17.8C152.2,191,131.5,191,110.7,191z
	 M158.8,63.2c-32.1,0-63.8,0-95.6,0c0,32,0,63.8,0,95.5c32,0,63.8,0,95.6,0C158.8,126.7,158.8,95,158.8,63.2z"/>
				<path d="M222.9,110.7c0-20.5,0-41,0-61.5c0-11.9,6.4-18.3,18.2-18.4c41.2,0,82.5,0,123.7,0c11.7,0,18.2,6.4,18.2,18.1
	c0,41.4,0,82.7,0,124.1c0,11.4-6.5,18-17.9,18c-41.5,0-83,0-124.4,0c-11.2,0-17.8-6.6-17.8-17.7
	C222.9,152.5,222.9,131.6,222.9,110.7z M255.2,63.1c0,32.1,0,63.9,0,95.5c32,0,63.8,0,95.5,0c0-32,0-63.7,0-95.5
	C318.9,63.1,287.2,63.1,255.2,63.1z"/>
				<path d="M30.9,302.7c0-20.5,0-41,0-61.5c0-11.9,6.4-18.3,18.3-18.3c41.2,0,82.5,0,123.7,0c11.7,0,18.1,6.4,18.1,18.1
	c0,41.4,0,82.7,0,124.1c0,11.4-6.5,17.9-17.9,17.9c-41.5,0-83,0-124.4,0c-11.1,0-17.7-6.6-17.7-17.7
	C30.9,344.4,30.9,323.5,30.9,302.7z M63.2,255.1c0,32.1,0,63.9,0,95.5c32.1,0,63.8,0,95.5,0c0-32,0-63.7,0-95.5
	C126.9,255.1,95.2,255.1,63.2,255.1z"/>
				<path d="M223,305.1c-0.8-43.9,32.8-80.2,77.3-82.1c43.6-1.9,80.9,32.6,82.6,77.5c1.6,44.1-33,81.1-77.6,82.4
	C262.3,384.2,224.7,351,223,305.1z M351,302.8c0-26.2-21.5-47.8-47.6-47.9c-26.7-0.1-48.4,21.4-48.3,47.9
	c0,26.6,21.5,48.1,48.2,48.1C329.5,350.9,350.9,329.3,351,302.8z"/>
			</svg>

		</>
	);
}

export default SvgDashboard;
