import * as React from 'react';

function SvgBookMoke(props) {
	return (
		<svg 
        version="1.1" 
        height="16"
        width="16"
        id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg"
        //  xmlns:xlink="http://www.w3.org/1999/xlink" 
         x="0px"
          y="0px"
	 viewBox="0 0 413.9 413.9" 
     style={{enableBackground:"new 0 0 413.9 413.9"}}
     {...props}
    //   xml:space="preserve"
      >
<path d="M399,113.2c-0.9,4.1-1.5,8.3-2.6,12.4c-9.3,34.9-40.2,58.7-76.4,58.9c-32,0.1-64,0-96,0c-1.5,0-3,0-4.7,0c0,8,0,15.8,0,24.1
	c5.4,0.7,10.9,1.4,16.4,2.1c15.1,1.9,30.3,3.5,45.3,5.9c16.6,2.7,27.9,16.5,28,33.5c0.1,29.1,0,58.3,0,87.4c0,12.4,0,24.8,0,37.1
	c0,8.1-4.3,12.7-12.4,12.8c-44.2,0.1-88.5,0.1-132.7,0c-8.1,0-12.4-4.6-12.4-12.7c0-11.8-0.1-23.5,0-35.3c0-2.2-0.7-3.6-2.4-5
	c-15.3-12.7-30.5-25.5-45.7-38.3c-12.2-10.2-15.9-24.5-10-38c6-13.5,21.2-22,36.2-20.1c8.1,1,15.1,4.2,21.5,10.1
	c0-21.4,0-42.2,0-63.6c-1.2,0-2.5,0-3.9,0c-17.5,0-35,0-52.5,0c-40.8-0.1-73.4-28.7-79.1-69.2c-0.1-0.7-0.4-1.4-0.7-2.1
	c0-5.3,0-10.5,0-15.8c0.2-0.6,0.6-1.1,0.6-1.7c5.6-39.9,37.7-68.9,77.6-69.1c75.8-0.3,151.7-0.3,227.5,0
	c35.9,0.1,66.4,24.2,75.6,58.8c1.1,4.2,1.8,8.5,2.6,12.7C399,103.2,399,108.2,399,113.2z M218.9,161.9c1.2,0.1,1.8,0.1,2.4,0.1
	c33.4,0,66.7,0,100.1-0.1c3.3,0,6.8-0.4,10-1.1c28.3-6.2,47.7-32.3,44.8-60.2c-3.1-29.8-26.9-51.4-56.8-51.5c-12.1,0-24.2,0-36.4,0
	c-62.9,0-125.7,0-188.6,0c-31.5,0-56.7,24.7-57,55.6c-0.3,31.7,24.8,57.1,56.5,57.3c18,0.1,36,0,54,0c1.1,0,2.2-0.1,3.5-0.2
	c0-1.6,0-2.9,0-4.3c0-10.1-0.2-20.3,0.1-30.4c0.5-20.7,20.8-36.1,40.9-31.4c15.5,3.6,26.2,16.7,26.5,32.6
	C219.1,139.4,218.9,150.5,218.9,161.9z M286.2,319.9c0.1-1,0.3-1.3,0.3-1.7c0-22.7,0.1-45.5,0-68.2c0-6.4-4.1-10.7-10.5-11.5
	c-22.4-2.8-44.8-5.6-67.3-8.4c-8.5-1.1-12.1-5-12.1-13.6c0-19.6,0-39.2,0-58.9c0-9.6,0.1-19.2,0-28.9c-0.1-7.8-7.1-13-14.3-11
	c-5.1,1.4-8.1,5.9-8.1,12.1c0,46.9,0,93.7,0,140.6c0,5.3-1.4,9.7-6.5,12.1c-5.2,2.4-9.6,0.8-13.7-2.8c-6.5-5.7-13.1-11.2-19.8-16.6
	c-5.8-4.6-14.3-3.6-18.6,1.8c-3.9,4.9-2.7,9.6,4,15.2c14.8,12.4,29.6,24.9,44.6,37.2c1.7,1.4,4.4,2.5,6.6,2.5
	c37.1,0.1,74.2,0.1,111.3,0.1C283.3,319.9,284.6,319.9,286.2,319.9z M174.1,364.5c37.6,0,74.9,0,112.1,0c0-7.5,0-14.6,0-21.7
	c-37.5,0-74.7,0-112.1,0C174.1,350.1,174.1,357.2,174.1,364.5z"/>
<path d="M274.3,112.1c12.2-12.3,24-24.2,35.9-35.9c1.9-1.8,4.4-3.5,6.9-4.2c4.8-1.3,9.5,1,11.9,5c2.6,4.5,2.2,9.6-1.5,13.5
	c-4.1,4.4-8.4,8.5-12.7,12.8c-10.4,10.4-20.8,20.9-31.3,31.3c-6.1,6-12.2,6-18.3-0.1c-6.8-6.8-13.6-13.6-20.4-20.4
	c-5.2-5.2-5.5-11.9-0.9-16.6c4.6-4.7,11.6-4.4,16.9,0.9C265.2,102.8,269.6,107.3,274.3,112.1z"/>
</svg>

	);
}

export default SvgBookMoke;
